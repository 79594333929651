import * as React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'

import NewCharacter from './overlays/NewCharacter'
import LoadCharacter from './overlays/LoadCharacter'
import DeleteCharacter from './overlays/DeleteCharacter'

export default function UserContent(props) {

    const boxStyle = {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }

    const cardStyle = {
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.75)',
    }

    const [newCharacterOpen, setNewCharacterOpen] = React.useState(false)
    const [loadCharacterOpen, setLoadCharacterOpen] = React.useState(false)
    const [deleteCharacterOpen, setDeleteCharacterOpen] = React.useState(false)

    return (
        <Box sx={boxStyle}>
            <Card sx={cardStyle}>
                <Box sx={{margin: 1.5}}>
                    <Button
                        fullWidth
                        type='submit'
                        variant='contained'
                        sx={{marginTop: 0}}
                        onClick={() => {setNewCharacterOpen(true)}}
                    >
                        New Character
                    </Button>
                    <Button
                        fullWidth
                        type='submit'
                        variant='contained'
                        sx={{marginTop: 2}}
                        onClick={() => {setLoadCharacterOpen(true)}}
                        disabled={props.characterIds.length === 0}
                    >
                        Load Character
                    </Button>
                    <Button
                        fullWidth
                        type='submit'
                        variant='contained'
                        sx={{marginTop: 2}}
                        onClick={() => {setDeleteCharacterOpen(true)}}
                        disabled={props.characterIds.length === 0}
                    >
                        Delete Character
                    </Button>
                </Box>
            </Card>

            <NewCharacter
                open={newCharacterOpen}
                setOpen={setNewCharacterOpen}
                user={props.user}
                characterIds={props.characterIds}
                setCharacterIds={props.setCharacterIds}
                setCharacterId={props.setCharacterId}
                setCharacterData={props.setCharacterData}
                setPage={props.setPage}
            />
            <LoadCharacter
                open={loadCharacterOpen}
                setOpen={setLoadCharacterOpen}
                user={props.user}
                characterIds={props.characterIds}
                setCharacterId={props.setCharacterId}
                setCharacterData={props.setCharacterData}
                setPage={props.setPage}
                setSelectedKeys={props.setSelectedKeys}
                setRollReady={props.setRollReady}
                setActions = {props.setActions}
                setRollValue = {props.setRollValue}
                setRerollValue = {props.setRerollValue}
            />
            <DeleteCharacter
                open={deleteCharacterOpen}
                setOpen={setDeleteCharacterOpen}
                user={props.user}
                characterIds={props.characterIds}
                setCharacterIds={props.setCharacterIds}
                setCharacterId={props.setCharacterId}
                setCharacterData={props.setCharacterData}
                setPage={props.setPage}
            />
        </Box>
    )
}
