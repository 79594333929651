import * as React from 'react'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'

import Label from '../utils/Label'

function LocalField(props)
{

    const textFieldStyle = {
        padding: 0,
        width: '100%',
        pointerEvents: (('editable' in props) && (!props.editable)) ? 'none' : 'auto',
    }

    const formattedStyle = {
        textAlign: props.multiline ? 'justify' : 'left',
        fontSize: props.fontSize,
    }

    const unformattedStyle = {
        padding: 0,
        width: '100%',
        textAlign: props.multiline ? 'justify' : 'center',
        fontSize: props.fontSize,
    }

    const checkValue = (event) => {
        const newValue = event.target.value
        if (props.type === 'int')
        {
            const intValue = parseInt(newValue, 10)
            if (!isNaN(intValue))
            {
                props.setValue(intValue)
            }
            else if ((newValue === '') || (newValue === '-'))
            {
                props.setValue(newValue)
            }
        }
        else if (props.type === 'float')
        {
            const floatValue = parseFloat(newValue)
            if (!isNaN(floatValue))
            {
                props.setValue(newValue.slice(-1) === '.' ? newValue : floatValue)
            }
            else if ((newValue === '') || (newValue === '-') || (newValue === '.'))
            {
                props.setValue(newValue)
            }
        }
        else
        {
            props.setValue(newValue)
        }
    }

    return(
        <TextField
            fullWidth
            multiline={props.multiline}
            variant='standard'
            size='small'
            label={props.label ? props.label : ''}
            value={props.value}
            onChange={(e) => {checkValue(e)}}
            sx={textFieldStyle}
            inputProps={props.formatted ? {style: formattedStyle} : {style: unformattedStyle}}
            InputProps={props.formatted ? {style: formattedStyle} : {style: unformattedStyle, disableUnderline: true}}
        />
    )
}

function ItemRow(props)
{

    const borderStyle = {
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
    }

    const itemStyle = {
        padding: 0,
        height: '100%',
        borderRadius: 0,
        border: '1px solid black',
    }

    const setName = (value) => {
        props.editItem(props.rowID, 'name', value)
    }

    const setQuantity = (value) => {
        props.editItem(props.rowID, 'quantity', value)
    }

    const setWeight = (value) => {
        props.editItem(props.rowID, 'weight', value)
    }

    return(
        <Box sx={borderStyle}>
            <Grid container spacing={0} columns={6} alignItems='center'>
                <Grid item xs={4} sx={itemStyle}>
                    <LocalField
                        editable={props.editable}
                        multiline={false}
                        formatted={false}
                        fontSize={16}
                        type='string'
                        value={props.name}
                        setValue={setName}
                    />
                </Grid>
                <Grid item xs={1} sx={itemStyle}>
                    <LocalField
                        editable={props.editable}
                        multiline={false}
                        formatted={false}
                        fontSize={16}
                        type='float'
                        value={props.quantity}
                        setValue={setQuantity}
                    />
                </Grid>
                <Grid item xs={1} sx={itemStyle}>
                    <LocalField
                        editable={props.editable}
                        multiline={false}
                        formatted={false}
                        fontSize={16}
                        type='float'
                        value={props.weight}
                        setValue={setWeight}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default function Inventory(props)
{

    const marginStyle = {
        marginTop: props.bigScreen ? '70px' : '62px',
        marginBottom: '66px',
    }

    const itemStyle = {
        height: '24px',
    }

    const iconStyle = {
        padding: 0,
        width:'100%',
        marginTop: '-3px',
        pointerEvents: props.editable ? 'auto' : 'none',
    }

    const rowKeyMap = {
        'name': 1,
        'quantity': 2,
        'weight': 3,
    }

    const addItem = (rowID) => {
        let postRows = props.characterData['inventory'].slice(rowID + 1)
        postRows = postRows.map(([rowID, name, quantity, weight]) => (
            [rowID + 1, name, quantity, weight]
        ))
        props.editCharacterData('inventory', [...props.characterData['inventory'].slice(0, rowID + 1), [rowID + 1, '', '', ''], ...postRows])
    }

    const editItem = (rowID, key, value) => {
        const row = props.characterData['inventory'][rowID]
        row[rowKeyMap[key]] = value
        props.editCharacterData('inventory', [...props.characterData['inventory'].slice(0, rowID), row, ...props.characterData['inventory'].slice(rowID + 1)])
    }

    const removeItem = (rowID) => {
        if (props.characterData['inventory'].length > 1)
        {
            let postRows = props.characterData['inventory'].slice(rowID + 1)
            postRows = postRows.map(([rowID, name, quantity, weight]) => (
                [rowID - 1, name, quantity, weight]
            ))
            props.editCharacterData('inventory', [...props.characterData['inventory'].slice(0, rowID), ...postRows])
        }
    }

    return(
        <Grid container spacing={0} columns={12} alignItems='center' sx={marginStyle}>

            <Grid item xs={1}>
                <Grid container spacing={0} columns={1} alignItems='center'>
                    <Grid item xs={1}>
                        <Label
                            justify='center'
                            fontSize={12}
                            label={'\u00A0'}
                        />
                    </Grid>
                    {props.characterData['inventory'].map(([rowID, name, quantity, weight]) => (
                        <Grid item xs={1} sx={itemStyle} key={rowID}>
                            <IconButton
                                disableRipple
                                size='small'
                                color='inherit'
                                onClick={() => {addItem(rowID)}}
                                sx={iconStyle}
                            >
                                <Label justify='center' fontSize='16' label={props.editable ? '+' : ''}/>
                            </IconButton>
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            <Grid item xs={10}>
                <Grid container spacing={0} columns={6} alignItems='center'>
                    <Grid item xs={4} >
                        <Label
                            justify='center'
                            fontSize={12}
                            label='Item Name'
                        />
                    </Grid>
                    <Grid item xs={1} >
                        <Label
                            justify='center'
                            fontSize={12}
                            label='#'
                        />
                    </Grid>
                    <Grid item xs={1} >
                        <Label
                            justify='center'
                            fontSize={12}
                            label='Kg'
                        />
                    </Grid>
                    <Grid item xs={6} sx={{borderBottom: '1px solid black'}}/>
                    {props.characterData['inventory'].map(([rowID, name, quantity, weight]) => (
                        <Grid item xs={6} sx={itemStyle} key={rowID}>
                            <ItemRow
                                editable={props.editable}
                                editItem = {editItem}
                                rowID={rowID}
                                name={name}
                                quantity={quantity}
                                weight={weight}
                            />
                        </Grid>
                    ))}
                    <Grid item xs={6} sx={{borderTop: '1px solid black'}}/>
                </Grid>
            </Grid>

            <Grid item xs={1}>
                <Grid container spacing={0} columns={1} alignItems='center'>
                    <Grid item xs={1}>
                        <Label
                            justify='center'
                            fontSize={12}
                            label={'\u00A0'}
                        />
                    </Grid>
                    {props.characterData['inventory'].map(([rowID, name, quantity, weight]) => (
                        <Grid item xs={1} sx={itemStyle} key={rowID}>
                            <IconButton
                                disableRipple
                                size='small'
                                color='inherit'
                                onClick={() => {removeItem(rowID)}}
                                sx={iconStyle}
                            >
                                <Label justify='center' fontSize='16' label={props.editable ? '-' : ''}/>
                            </IconButton>
                        </Grid>
                    ))}
                </Grid>
            </Grid>

        </Grid>
    )
}
