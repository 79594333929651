import * as React from 'react'
import Paper from '@mui/material/Paper'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'

import {BsFillPersonLinesFill} from 'react-icons/bs'
import {AiOutlineUnorderedList} from 'react-icons/ai'
import {BiSolidBookBookmark} from 'react-icons/bi'
import {GiCubes} from 'react-icons/gi'

export default function CharacterNavigation(props) {

  const iconStyle = {
      fontSize: '25px',
      color: 'black',
  }

    return (
        <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
            <BottomNavigation
                showLabels
                value={props.page}
                onChange={(event, newPage) => {props.setPage(newPage)}}
            >
                <BottomNavigationAction label='Statistics' value='Statistics' icon={<BsFillPersonLinesFill style={iconStyle}/>}/>
                <BottomNavigationAction label='Inventory' value='Inventory' icon={<AiOutlineUnorderedList style={iconStyle}/>}/>
                <BottomNavigationAction label='Notes' value='Notes' icon={<BiSolidBookBookmark style={iconStyle}/>}/>
                <BottomNavigationAction label='Play' value='Play' icon={<GiCubes style={iconStyle}/>}/>
            </BottomNavigation>
        </Paper>
    )
}
