import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import Loading from '../../utils/Loading'

import {Auth} from 'aws-amplify'

export default function ChangePassword(props) {

    const [password, setPassword] = React.useState('')
    const [newPassword, setNewPassword] = React.useState('')
    const [confirmNewPassword, setConfirmNewPassword] = React.useState('')
    const [error, setError] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    const handleClose = () => {
        setPassword('')
        setNewPassword('')
        setConfirmNewPassword('')
        setLoading(false)
        props.setOpen(false)
    }

    const handleSubmit = async () => {
        setLoading(true)
        try {
            await Auth.changePassword(props.user, password, newPassword)
            props.setAlert('Password has been changed.')
            handleClose()
        }
        catch (error) {
            setError(error.message)
        }
        setLoading(false)
    }

  return (
    <Dialog
        sx={{alignContent: ''}}
        PaperProps={{style: {width: '100vw'}}}
        open={props.open}
        onClose={handleClose}
    >
        <DialogTitle sx={{textAlign: 'center'}}>
            Change Password
        </DialogTitle>
        <DialogContent>
            <TextField
                fullWidth
                autoFocus
                margin='normal'
                label='Current Password'
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
                required
                fullWidth
                margin='normal'
                label='New Password'
                type='password'
                value={newPassword}
                onChange={(e) => {setNewPassword(e.target.value)}}
            />
            <TextField
                required
                fullWidth
                margin='normal'
                label='Confirm New Password'
                type='password'
                value={confirmNewPassword}
                onChange={(e) => {setConfirmNewPassword(e.target.value)}}
                error={newPassword !== confirmNewPassword}
            />
        </DialogContent>
        <DialogActions sx={{paddingTop: 0, paddingLeft: 1.5, paddingRight: 1.5, justifyContent: 'space-between'}}>
            <Button
                onClick={handleClose}
                color='primary'
            >
                Cancel
            </Button>
            <Button
                onClick={handleSubmit}
                color='primary'
                disabled={(password === '') || (newPassword === '') || (newPassword !== confirmNewPassword)}
            >
                Change Password
            </Button>
        </DialogActions>

        {error && (
            <Snackbar open={true} autoHideDuration={6000} onClose={() => setError(null)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert
                    sx={{width: '100%'}}
                    severity='error'
                    onClose={() => setError(null)}>
                    {error}
                </Alert>
            </Snackbar>
        )}
        <Loading open={loading}/>
    </Dialog>
  )
}
