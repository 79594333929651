import * as React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import VerifyAccount from './overlays/VerifyAccount'
import Loading from '../utils/Loading'

import {Auth} from 'aws-amplify'

export default function CreateAccount(props) {

    const boxStyle = {
        marginTop: props.bigScreen ? '90px' : '82px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }

    const cardStyle = {
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.75)',
    }

    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [confirmPassword, setConfirmPassword] = React.useState('')
    const [verifyAccountOpen, setVerifyAccountOpen] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    const handleSubmit = async () => {
        setLoading(true)
        try {
            await Auth.signUp({
                username: email,
                password: password,
                attributes: {
                    email: email,
                }
            })
            setVerifyAccountOpen(true)
        }
        catch (error) {
            setLoading(false)
            setError(error.message)
        }
    }

    return (
        <Box sx={boxStyle}>
            <Card sx={cardStyle}>
                <Box sx={{margin: 1}}>
                    <TextField
                        sx={{marginTop: 0.7}}
                        required
                        fullWidth
                        autoFocus
                        margin='normal'
                        label='Email'
                        type='email'
                        value={email}
                        onChange={(e) => {setEmail(e.target.value)}}
                    />
                    <TextField
                        required
                        fullWidth
                        margin='normal'
                        label='Password'
                        type='password'
                        value={password}
                        onChange={(e) => {setPassword(e.target.value)}}
                    />
                    <TextField
                        required
                        fullWidth
                        margin='normal'
                        label='Confirm Password'
                        type='password'
                        value={confirmPassword}
                        onChange={(e) => {setConfirmPassword(e.target.value)}}
                        error={password !== confirmPassword}
                    />
                    <Button
                        fullWidth
                        type='submit'
                        variant='contained'
                        sx={{marginTop: 3, marginBottom: 2}}
                        onClick={() => {handleSubmit()}}
                        disabled={(email === '') || (password === '') || (password !== confirmPassword)}
                    >
                        Create Account
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link
                                href='#'
                                variant='body2'
                                onClick={() => {props.setPage('Home')}}
                            >
                                Have an account? Sign In.
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Card>

            <VerifyAccount
                open={verifyAccountOpen}
                email={email}
                password={password}
                setUser={props.setUser}
                setCharacterIds={props.setCharacterIds}
                setPage={props.setPage}
            />
            {error && (
                <Snackbar open={true} autoHideDuration={6000} onClose={() => setError(null)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                    <Alert
                        sx={{width: '100%'}}
                        severity='error'
                        onClose={() => setError(null)}>
                        {error}
                    </Alert>
                </Snackbar>
            )}
            <Loading open={loading}/>
        </Box>
    )
}
