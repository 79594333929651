import * as React from 'react'
import Backdrop from '@mui/material/Backdrop'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

export default function Loading(props) {
    return (
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={props.open}
        >
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                {props.message && <Typography variant='h6' component='div' sx={{pb:2}}>{props.message}</Typography>}
                <CircularProgress color='inherit' />
            </div>
        </Backdrop>
    )
}
