import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import SignIn from '../accountManagement/SignIn'
import UserContent from '../userContent/UserContent'
import GameContent from '../gameContent/GameContent'

export default function Home(props) {

    const boxStyle = {
        marginTop: props.bigScreen ? '88px' : '80px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }

    return (
        <Box sx={boxStyle}>
            <Typography sx={{margin: 0, fontSize: '48px', fontWeight: 'bold', fontFamily: 'MedievalSharp'}} component='h1' variant='h4'>
                <img src='logo.png' height='60px' alt='Oxidius.com'/> {/*https://www.fontspace.com/bu-oscar-diggs-font-f16830*/}
            </Typography>
            <Typography sx={{marginTop: -1, marginBottom: 2.5, fontWeight: 'bold'}} component='h2' variant='h5' fontSize='13px'>
                A mobile companion for your adventures.
            </Typography>

            {props.user ? (
                <UserContent
                    user={props.user}
                    characterIds={props.characterIds}
                    setCharacterIds={props.setCharacterIds}
                    setCharacterId={props.setCharacterId}
                    setCharacterData={props.setCharacterData}
                    setPage={props.setPage}
                    setSelectedKeys={props.setSelectedKeys}
                    setRollReady={props.setRollReady}
                    setActions = {props.setActions}
                    setRollValue = {props.setRollValue}
                    setRerollValue = {props.setRerollValue}
                />
                ) : (
                <SignIn
                    setUser={props.setUser}
                    setCharacterIds={props.setCharacterIds}
                    setPage={props.setPage}
                />
            )}

            <GameContent
                setPage={props.setPage}
            />

        </Box>
    )
}
