import * as React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Popover from '@mui/material/Popover'

import {BsCircle} from 'react-icons/bs'
import {FaShapes} from 'react-icons/fa6'
import {GiTwoCoins} from 'react-icons/gi'
import {RiSwordFill} from 'react-icons/ri'

import Label from '../../utils/Label'
import Field from '../../utils/Field'
import SelectableField from '../../utils/SelectableField'

/*
Props includes:
editable: is the combat table editable
type: the selected sype
setType: the function to select type
*/
function TypeButton(props) {

    const buttonStyle = {
        padding: 0,
        width: '100%',
        height: '100%',
        borderRadius: 0,
        border: 'none',
        color: 'black',
        pointerEvents: props.editable ? 'auto' : 'none',
    }

    const iconStyle = {
        marginBottom: '3px',
        fontSize: 16,
    }

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const handleClickType = (type) => {
        props.editCharacterData(props.characterKey, type)
        handlePopoverClose()
    }

    return (
        <>
            <IconButton
                disableRipple
                size='small'
                onClick={handlePopoverOpen}
                style={buttonStyle}
            >
                {props.characterData[props.characterKey] === '' && <BsCircle style={iconStyle}/>}
                {props.characterData[props.characterKey] === 'profession' && <GiTwoCoins style={iconStyle}/>}
                {props.characterData[props.characterKey] === 'hobby' && <FaShapes style={iconStyle}/>}
                {props.characterData[props.characterKey] === 'armament' && <RiSwordFill style={iconStyle}/>}
            </IconButton>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
            >
                <Box p={1} sx={{cursor: 'pointer'}}>
                    <Typography onClick={() => {handleClickType('')}}>None</Typography>
                    <Typography onClick={() => {handleClickType('profession')}}>Profession</Typography>
                    <Typography onClick={() => {handleClickType('hobby')}}>Hobby</Typography>
                    <Typography onClick={() => {handleClickType('armament')}}>Armament</Typography>
                </Box>
            </Popover>
        </>
    )
}

function DomainRow(props)
{

    const containerStyle = {
        padding: 0,
        margin: 0,
        height: '100%',
        border: '1px solid black',
        backgroundColor: props.selectedKeys.includes(`domainName${props.characterKey}`) ? '#ece6db' : '#ffffff',
    }

    const borderStyle = {
        height: '100%',
        marginTop: '-1px',
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
    }

    return(
        <Grid
            container
            spacing={0}
            columns={7}
            alignItems='center'
            sx={containerStyle}>

            <Grid item xs={1}>
                <TypeButton
                    editable={props.editable}
                    characterKey={`domainType${props.characterKey}`}
                    characterData={props.characterData}
                    editCharacterData={props.editCharacterData}
                />
            </Grid>


            <Grid item xs={5} sx={borderStyle}>
                <SelectableField
                    editable={props.editable}
                    multiline={false}
                    formatted={false}
                    fontSize={16}
                    type='string'
                    characterKey={`domainName${props.characterKey}`}
                    characterData={props.characterData}
                    editCharacterData={props.editCharacterData}
                    selectedKeys={props.selectedKeys}
                    setSelectedKeys={props.setSelectedKeys}
                    setRollReady={props.setRollReady}
                />
            </Grid>

            <Grid item xs={1}>
                <Field
                    editable={props.editable}
                    multiline={false}
                    formatted={false}
                    fontSize={16}
                    type='int'
                    characterKey={`domainTier${props.characterKey}`}
                    characterData={props.characterData}
                    editCharacterData={props.editCharacterData}
                    selectedKeys={props.selectedKeys}
                    setSelectedKeys={props.setSelectedKeys}
                    setRollReady={props.setRollReady}
                />
            </Grid>

        </Grid>
    )
}

function DomainColumn(props)
{

    const borderStyle = {
        border: '1px solid black',
    }

    return(
        <Grid container spacing={0} columns={7} alignItems='center'>

            <Grid item xs={1}>
                <Label
                    justify='center'
                    fontSize={12}
                    label='Type'
                />
            </Grid>
            <Grid item xs={5}>
                <Label
                    justify='center'
                    fontSize={12}
                    label='Domain Name'
                />
            </Grid>
            <Grid item xs={1}>
                <Label
                    justify='center'
                    fontSize={12}
                    label='Tier'
                />
            </Grid>

            <Box sx={borderStyle}>
                {props.domainRows.map((characterKey) => (
                    <Grid item xs={7} key={characterKey}>
                        <DomainRow
                            editable={props.editable}
                            characterKey={characterKey}
                            characterData={props.characterData}
                            editCharacterData={props.editCharacterData}
                            selectedKeys={props.selectedKeys}
                            setSelectedKeys={props.setSelectedKeys}
                            setRollReady={props.setRollReady}
                        />
                    </Grid>
                ))}
            </Box>
        </Grid>
    )
}

export default function DomainTable(props)
{
    return(
        <Grid container spacing={0.5} columns={2} alignItems='center'>
            <Grid item xs={1}>
                <DomainColumn
                    editable={props.editable}
                    domainRows={['01', '02', '03', '04', '05', '06']}
                    characterData={props.characterData}
                    editCharacterData={props.editCharacterData}
                    selectedKeys={props.selectedKeys}
                    setSelectedKeys={props.setSelectedKeys}
                    setRollReady={props.setRollReady}
                />
            </Grid><Grid item xs={1}>
                <DomainColumn
                    editable={props.editable}
                    domainRows={['07', '08', '09', '10', '11', '12']}
                    characterData={props.characterData}
                    editCharacterData={props.editCharacterData}
                    selectedKeys={props.selectedKeys}
                    setSelectedKeys={props.setSelectedKeys}
                    setRollReady={props.setRollReady}
                />
            </Grid>
        </Grid>
    )
}
