import * as React from 'react'
import Grid from '@mui/material/Grid'

import Field from '../../utils/Field'
import SelectableField from '../../utils/SelectableField'

export default function InformationTable(props)
{

    return(
        <Grid container spacing={1} columns={2}>
            <Grid item xs={1}>
                <Field
                    editable={props.editable}
                    multiline={false}
                    formatted={true}
                    fontSize={14}
                    label='Name'
                    type='string'
                    characterKey='name'
                    characterData={props.characterData}
                    editCharacterData={props.editCharacterData}
                />
            </Grid>
            <Grid item xs={1}>
                <SelectableField
                    editable={props.editable}
                    multiline={false}
                    formatted={true}
                    fontSize={14}
                    label='Culture'
                    type='string'
                    characterKey='culture'
                    characterData={props.characterData}
                    editCharacterData={props.editCharacterData}
                    selectedKeys={props.selectedKeys}
                    setSelectedKeys={props.setSelectedKeys}
                    setRollReady={props.setRollReady}
                />
            </Grid>
        </Grid>
    )
}
