import * as React from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

export default function SelectableField(props)
{

    const buttonStyle = {
        padding: 0,
        width: '100%',
        height: '100%',
        borderRadius: props.characterKey === 'instinct' ? '50%' : 0,
        border: '0px solid black',
        textTransform: 'none',
    }

    const boxStyle = {
        width: '100%',
        height: '100%',
    }

    const textFieldStyle = {
        padding: 0,
        width: '100%',
        height: '100%',
        pointerEvents: (('editable' in props) && !props.editable) ? 'none' : 'auto',
        marginTop: props.characterKey.includes('domain') ? '2.25px' : '0',
    }

    const formattedStyle = {
        height: '100%',
        textAlign: props.multiline ? 'justify' : 'left',
        fontSize: props.fontSize,
    }

    const unformattedStyle = {
        padding: 0,
        width: '100%',
        height: '100%',
        textAlign: props.multiline ? 'justify' : 'center',
        fontSize: props.fontSize,
    }

    const checkValue = (event) => {
        const newValue = event.target.value
        if (props.type === 'int')
        {
            const intValue = parseInt(newValue, 10)
            if (!isNaN(intValue))
            {
                props.editCharacterData(props.characterKey, intValue)
            }
            else if ((newValue === '') || (newValue === '-'))
            {
                props.editCharacterData(props.characterKey, newValue)
            }
        }
        else if (props.type === 'float')
        {
            const floatValue = parseFloat(newValue)
            if (!isNaN(floatValue))
            {
                props.editCharacterData(props.characterKey, newValue.slice(-1) === '.' ? newValue : floatValue)
            }
            else if ((newValue === '') || (newValue === '-') || (newValue === '.'))
            {
                props.editCharacterData(props.characterKey, newValue)
            }
        }
        else
        {
            props.editCharacterData(props.characterKey, newValue)
        }
    }


    const setSelected = () => {
        if (('editable' in props) && (!props.editable))
        {
            if (props.selectedKeys.includes(props.characterKey))
            {
                const otherKey = props.selectedKeys.filter(item => item !== props.characterKey)
                props.setSelectedKeys(otherKey)
                props.setRollReady(
                    (otherKey.length === 1) &&
                    (
                        (props.characterKey === 'culture') ||
                        (props.characterKey.includes('domain'))
                    )
                )
            }
            else
            {
                if (props.characterKey === 'instinct')
                {
                    props.setSelectedKeys(['instinct'])
                    props.setRollReady(true)
                }
                else if (props.characterKey === 'culture')
                {
                    const otherKey = props.selectedKeys.filter(item => ((!item.includes('domain')) && (item !== 'instinct')))
                    props.setSelectedKeys([
                        props.characterKey,
                        ...otherKey
                    ])
                    props.setRollReady(otherKey.length === 1)
                }
                else if (props.characterKey.includes('domain'))
                {
                    if (props.characterData[props.characterKey.replace('Name', 'Tier')] !== '')
                    {
                        const otherKey = props.selectedKeys.filter(item => (!item.includes('domain')) && (item !== 'culture') && (item !== 'instinct'))
                        props.setSelectedKeys([
                            props.characterKey,
                            ...otherKey
                        ])
                        props.setRollReady(otherKey.length === 1)
                    }
                }
                else
                {
                    if (props.characterData[props.characterKey] !== '')
                    {
                        const otherKey = props.selectedKeys.filter(item => (item.includes('domain')) || (item === 'culture'))
                        props.setSelectedKeys([
                            props.characterKey,
                            ...otherKey
                        ])
                        props.setRollReady(true)
                    }
                }
            }
        }
        else
        {
            props.setSelectedKeys([])
            props.setRollReady(false)
        }
    }

    return (
        <ToggleButton
            sx={{
                ...buttonStyle,
                '&.Mui-selected': {
                    backgroundColor: '#ece6db',
                    '&:hover': {
                        backgroundColor: '#ece6db',
                    },
                },
                '&:hover': {
                    backgroundColor: props.editable ? 'initial' : 'default',
                },
            }}
            value={props.characterKey}
            variant='outlined'
            disableRipple
            selected={props.selectedKeys.includes(props.characterKey)}
            onChange={() => {setSelected()}}
        >
            <Box sx={boxStyle}>
                <TextField
                    fullWidth
                    multiline={props.multiline}
                    variant='standard'
                    size='small'
                    label={props.label ? props.label : ''}
                    value={props.characterData[props.characterKey]}
                    onChange={(e) => {checkValue(e)}}
                    sx={textFieldStyle}
                    inputProps={props.formatted ? {style: formattedStyle} : {style: unformattedStyle}}
                    InputProps={props.formatted ? {style: formattedStyle} : {style: unformattedStyle, disableUnderline: true}}
                />
            </Box>
        </ToggleButton>
    )
}