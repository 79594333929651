import * as React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import ChangePassword from './overlays/ChangePassword'
import DeleteAccount from './overlays/DeleteAccount'

export default function AccountManagement(props) {

    const boxStyle = {
        marginTop: props.bigScreen ? '90px' : '82px',
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }

    const cardStyle = {
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.75)',
    }

    const [changePasswordOpen, setChangePasswordOpen] = React.useState(false)
    const [deleteAccountOpen, setDeleteAccountOpen] = React.useState(false)
    const [alert, setAlert] = React.useState(null)

    return (
        <Box sx={boxStyle}>
            <Card sx={cardStyle}>
                <Box sx={{margin: 1.5}}>
                    <Button
                        fullWidth
                        type='submit'
                        variant='contained'
                        sx={{marginTop: 0}}
                        onClick={() => {setChangePasswordOpen(true)}}
                    >
                        Change Password
                    </Button>
                    <Button
                        fullWidth
                        type='submit'
                        variant='contained'
                        sx={{marginTop: 2}}
                        onClick={() => {setDeleteAccountOpen(true)}}
                    >
                        Delete Account
                    </Button>
                </Box>
            </Card>

            <ChangePassword
                open={changePasswordOpen}
                setOpen={setChangePasswordOpen}
                user={props.user}
                setAlert={setAlert}
            />
            <DeleteAccount
                open={deleteAccountOpen}
                setOpen={setDeleteAccountOpen}
                user={props.user}
                setUser={props.setUser}
                setCharacterIds={props.setCharacterIds}
                setPage={props.setPage}
            />

            {alert && (
                <Snackbar open={true} autoHideDuration={6000} onClose={() => setAlert(null)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                    <Alert
                        sx={{width: '100%'}}
                        severity='success'
                        onClose={() => setAlert(null)}>
                        {alert}
                    </Alert>
                </Snackbar>
            )}
        </Box>
    )
}
