import * as React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import Label from '../../utils/Label'
import Field from '../../utils/Field'

function HealthCell(props)
{

    const rimStyle = {
        padding: '2%',
        aspectRatio: '1/1',
    }

    const cellStyle = {
        padding: '5%',
        aspectRatio: '1/1',
        border: '1.5px solid black',
        backgroundImage: 'url(slash.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }

    const fieldStyle = {
        width: '100%',
        height: '100%',
        aspectRatio: '1/1',
        borderRadius: '100%',
        border: '1.5px solid black',
    }

    const itemStyle = {
        width: '100%',
        height: '100%',
        aspectRatio: '1/1',
    }

    return(
        <Box sx={rimStyle}>
            <Box sx={cellStyle}>
                <Grid container spacing={0} columns={2}>
                    <Grid item xs={1} sx={itemStyle}>
                        <Box sx={fieldStyle}>
                            <Field
                                multiline={false}
                                formatted={false}
                                fontSize={16}
                                type='int'
                                characterKey={props.currentValueKey}
                                characterData={props.characterData}
                                editCharacterData={props.editCharacterData}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={1} sx={itemStyle}/>
                    <Grid item xs={1} sx={itemStyle}/>
                    <Grid item xs={1} sx={itemStyle}>
                        <Box sx={fieldStyle}>
                            <Field
                                editable={props.editable}
                                multiline={false}
                                formatted={false}
                                fontSize={16}
                                type='int'
                                characterKey={props.totalValueKey}
                                characterData={props.characterData}
                                editCharacterData={props.editCharacterData}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default function HealthTable(props)
{

    const healthKeys = [
        ['currentPhysicalHealth', 'totalPhysicalHealth'],
        ['currentPhysicalBuffer', 'totalPhysicalBuffer'],
        ['currentSocialHealth', 'totalSocialHealth'],
        ['currentSocialBuffer', 'totalSocialBuffer'],
        ['currentMentalHealth', 'totalMentalHealth'],
        ['currentMentalBuffer', 'totalMentalBuffer'],
    ]

    return(
        <Grid container spacing={0} columns={2}>
            <Grid item xs={1}>
                <Label
                    justify='center'
                    fontSize={11}
                    label='HP'
                />
            </Grid>
            <Grid item xs={1}>
                <Label
                    justify='center'
                    fontSize={11}
                    label='HB'
                />
            </Grid>
            {healthKeys.map(([currentValueKey, totalValueKey]) => (
                <Grid item xs={1} key={currentValueKey}>
                    <HealthCell
                        editable={props.editable}
                        currentValueKey={currentValueKey}
                        totalValueKey={totalValueKey}
                        characterData={props.characterData}
                        editCharacterData={props.editCharacterData}
                    />
                </Grid>
            ))}
        </Grid>
    )
}
