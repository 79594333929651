import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

import Loading from '../../utils/Loading'

import {API} from 'aws-amplify'

export default function NewCharacter(props) {

    const emptyCharacter = {
        'name': '',
        'culture': '',
        'physicalPower': '',
        'physicalFinesse': '',
        'physicalPerception': '',
        'socialPower': '',
        'socialFinesse': '',
        'socialPerception': '',
        'mentalPower': '',
        'mentalFinesse': '',
        'mentalPerception': '',
        'currentPhysicalHealth': '',
        'totalPhysicalHealth': '',
        'currentPhysicalBuffer': '',
        'totalPhysicalBuffer': '',
        'currentSocialHealth': '',
        'totalSocialHealth': '',
        'currentSocialBuffer': '',
        'totalSocialBuffer': '',
        'currentMentalHealth': '',
        'totalMentalHealth': '',
        'currentMentalBuffer': '',
        'totalMentalBuffer': '',
        'instinct' : '',
        'currentReactionDice' : '',
        'totalReactionDice' : '',
        'currentMovementSpeed' : '',
        'totalMovementSpeed' : '',
        'domainType01' : '',
        'domainName01' : '',
        'domainTier01' : '',
        'domainType02' : '',
        'domainName02' : '',
        'domainTier02' : '',
        'domainType03' : '',
        'domainName03' : '',
        'domainTier03' : '',
        'domainType04' : '',
        'domainName04' : '',
        'domainTier04' : '',
        'domainType05' : '',
        'domainName05' : '',
        'domainTier05' : '',
        'domainType06' : '',
        'domainName06' : '',
        'domainTier06' : '',
        'domainType07' : '',
        'domainName07' : '',
        'domainTier07' : '',
        'domainType08' : '',
        'domainName08' : '',
        'domainTier08' : '',
        'domainType09' : '',
        'domainName09' : '',
        'domainTier09' : '',
        'domainType10' : '',
        'domainName10' : '',
        'domainTier10' : '',
        'domainType11' : '',
        'domainName11' : '',
        'domainTier11' : '',
        'domainType12' : '',
        'domainName12' : '',
        'domainTier12' : '',
        'inventory' : [[0, '', '', '']],
        'notes': '',
    }

    const [characterId, setCharacterId] = React.useState('')
    const [loading, setLoading] = React.useState(false)

    const handleClose = () => {
        setCharacterId('')
        props.setOpen(false)
    }

    const handleCreate = async () => {
        setLoading(true)
        await API.post(
            'UserContentAPI',
            `/users/${props.user.username}/characters/${characterId}`,
            {body: emptyCharacter}
        )
        props.setCharacterIds([...props.characterIds, characterId])
        props.setCharacterId(characterId)
        props.setCharacterData(emptyCharacter)
        props.setPage('Statistics')
    }

  return (
    <Dialog
        sx={{alignContent: ''}}
        PaperProps={{style: {width: '100vw'}}}
        open={props.open}
        onClose={handleClose}
    >
        <DialogTitle sx={{textAlign: 'center'}}>
            New Character
        </DialogTitle>
        <DialogContent>
            <TextField
                fullWidth
                autoFocus
                margin='dense'
                label='Sheet Name'
                type='text'
                helperText={
                    props.characterIds.includes(characterId) ?
                    'This label already exists.' :
                    'A short label, not your character name.'
                }
                value={characterId}
                onChange={(e) => setCharacterId(e.target.value)}

            />
        </DialogContent>
        <DialogActions sx={{paddingTop: 0, paddingLeft: 1.5, paddingRight: 1.5, justifyContent: 'space-between'}}>
            <Button
                onClick={handleClose}
                color='primary'
            >
                Cancel
            </Button>
            <Button
                onClick={handleCreate}
                color='primary'
                disabled={(characterId === '') || (props.characterIds.includes(characterId))}
            >
                Create
            </Button>
        </DialogActions>
        <Loading open={loading}/>
    </Dialog>
  )
}
