import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import Loading from '../../utils/Loading'

import {Auth, API} from 'aws-amplify'

export default function DeleteAccount(props) {

    const [confirm, setConfirm] = React.useState('')
    const [error, setError] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    const handleClose = () => {
        setConfirm(false)
        props.setOpen(false)
        setLoading(false)

    }

    const handleSubmit = async () => {
        setLoading(true)
        try {
            await API.del('UserContentAPI', `/users/${props.user.username}`)
            await Auth.deleteUser()
            props.setUser(null)
            props.setCharacterIds([])
            props.setPage('Home')
        }
        catch (error) {
            setError(error.message)
        }
        setLoading(false)
    }

  return (
    <Dialog
        sx={{alignContent: ''}}
        PaperProps={{style: {width: '100vw'}}}
        open={props.open}
        onClose={handleClose}
    >
        <DialogTitle sx={{textAlign: 'center'}}>
            Delete Account
        </DialogTitle>
        <DialogContent>
            <FormControlLabel
                control={
                    <Checkbox
                        color='primary'
                        value={confirm}
                        onChange={() => {setConfirm(!confirm)}}
                    />
                }
                label='I want to permanently delete my account.'
            />
        </DialogContent>
        <DialogActions sx={{paddingTop: 0, paddingLeft: 1.5, paddingRight: 1.5, justifyContent: 'space-between'}}>
            <Button
                onClick={handleClose}
                color='primary'
            >
                Cancel
            </Button>
            <Button
                onClick={handleSubmit}
                color='primary'
                disabled={!confirm}
            >
                Delete Account
            </Button>
        </DialogActions>

        {error && (
            <Snackbar open={true} autoHideDuration={6000} onClose={() => setError(null)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert
                    sx={{width: '100%'}}
                    severity='error'
                    onClose={() => setError(null)}>
                    {error}
                </Alert>
            </Snackbar>
        )}
        <Loading open={loading}/>
    </Dialog>
  )
}
