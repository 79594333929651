import * as React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

export default function Label(props)
{
    const textStyle = {
        fontSize: props.fontSize,
    }

    return(
        <Typography variant='h7' sx={{flexGrow: 1}}>
            <Box display='flex' justifyContent={props.justify} alignItems='center' height='100%'>
                <Typography variant='h7' sx={textStyle}>
                    {props.label}
                </Typography>
            </Box>
        </Typography>
    )
}
