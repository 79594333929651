import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'

import Loading from '../../utils/Loading'

import {API} from 'aws-amplify'

export default function LoadCharacter(props) {

    const [characterId, setCharacterId] = React.useState('')
    const [confirm, setConfirm] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    const handleClose = () => {
        setCharacterId('')
        props.setOpen(false)
    }

    const handleDelete = async () => {
        setLoading(true)
        await API.del(
            'UserContentAPI',
            `/users/${props.user.username}/characters/${characterId}`
        )
        props.setCharacterIds(props.characterIds.filter(id => id !== characterId))
        handleClose()
        setLoading(false)
    }

    return (
        <Dialog
            sx={{alignContent: ''}}
            PaperProps={{style: {width: '100vw'}}}
            open={props.open}
            onClose={handleClose}
        >
            <DialogTitle sx={{textAlign: 'center'}}>
                Delete Character
            </DialogTitle>
            <DialogContent>
                <Select
                    fullWidth
                    autoFocus
                    margin='dense'
                    value={characterId}
                    onChange={(e) => setCharacterId(e.target.value)}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: '256px',
                                overflow: 'auto',
                            },
                        },
                    }}
                >
                    {props.characterIds.map((id) => (
                        <MenuItem key={id} value={id}>
                            {id}
                        </MenuItem>
                    ))}
                </Select>
                <FormControlLabel
                    sx={{marginTop: 2}}
                    control={
                        <Checkbox
                            color='primary'
                            value={confirm}
                            checked={confirm}
                            onChange={() => {setConfirm(!confirm)}}
                        />
                    }
                    label='I want to permanently delete this character.'
                />
            </DialogContent>
            <DialogActions sx={{paddingTop: 0, paddingLeft: 1.5, paddingRight: 1.5, justifyContent: 'space-between'}}>
                <Button
                    onClick={handleClose}
                    color='primary'
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleDelete}
                    color='primary'
                    disabled={(characterId === '') || (!confirm)}
                >
                    Delete
                </Button>
            </DialogActions>
            <Loading open={loading}/>
        </Dialog>
    )
}