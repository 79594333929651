import * as React from 'react'
import TextField from '@mui/material/TextField'

export default function Field(props)
{

    const textFieldStyle = {
        padding: 0,
        width: '100%',
        height: '100%',
        pointerEvents: (('editable' in props) && (!props.editable)) ? 'none' : 'auto',
        marginTop: props.characterKey.includes('domain') ? '2.25px' : '0',
    }

    const formattedStyle = {
        height: '100%',
        textAlign: props.multiline ? 'justify' : 'left',
        fontSize: props.fontSize,
    }

    const unformattedStyle = {
        padding: 0,
        width: '100%',
        height: '100%',
        textAlign: props.multiline ? 'justify' : 'center',
        fontSize: props.fontSize,
    }

    const checkValue = (event) => {
        const newValue = event.target.value
        if (props.type === 'int')
        {
            const intValue = parseInt(newValue, 10)
            if (!isNaN(intValue))
            {
                props.editCharacterData(props.characterKey, intValue)
            }
            else if ((newValue === '') || (newValue === '-'))
            {
                props.editCharacterData(props.characterKey, newValue)
            }
        }
        else if (props.type === 'float')
        {
            const floatValue = parseFloat(newValue)
            if (!isNaN(floatValue))
            {
                props.editCharacterData(props.characterKey, newValue.slice(-1) === '.' ? newValue : floatValue)
            }
            else if ((newValue === '') || (newValue === '-') || (newValue === '.'))
            {
                props.editCharacterData(props.characterKey, newValue)
            }
        }
        else
        {
            props.editCharacterData(props.characterKey, newValue)
        }
    }

    return(
        <TextField
            fullWidth
            multiline={props.multiline}
            variant='standard'
            size='small'
            label={props.label ? props.label : ''}
            value={props.characterData[props.characterKey]}
            onChange={(e) => {checkValue(e)}}
            sx={textFieldStyle}
            inputProps={props.formatted ? {style: formattedStyle} : {style: unformattedStyle}}
            InputProps={props.formatted ? {style: formattedStyle} : {style: unformattedStyle, disableUnderline: true}}
        />
    )
}
