import React from 'react'
import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export default function Rules(props) {

    const boxStyle = {
        marginTop: props.bigScreen ? '76px' : '66px',
        marginBottom: '32px',
    }

    const bodyStyle = {
        marginTop: '6px',
    }

    const afterTableStyle = {
        marginTop: '10px',
    }

    const bulletStyle = {
        marginTop: 0,
    }

    return (
        <Box sx={boxStyle}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography variant='h4'>Introduction</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Typography variant='body1'>
                        Oxidius is a tabletop role-playing game that emphasizes immersive character design and fast-paced, realistic combat scenarios. When playing Oxidius, one person, referred to as the Storyteller, narrates the events and circumstances that the other players’ characters experience while every other player narrates the thoughts and actions of a single character. Details on how to create a character can be found in the Character Creation section.
                    </Typography>
                    <Typography variant='body1'>
                        Gameplay is divided between three components:
                    </Typography>
                    <ul>
                        <li>Role-Playing periods constitute players describing to the Storyteller what they would like their characters to do, and the Storyteller facilitating these interactions.</li>
                        <li>Combat periods constitute turn-based gameplay in which the Storyteller and the players take turns choosing which specific actions they wish to attempt and roll dice to determine how effective they are in combat.</li>
                        <li>Downtime periods constitute opportunities for players to have their characters explore, forage, craft, or otherwise perform tasks that take significant lengths of time.</li>
                    </ul>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography variant='h4'>Character Creation</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>Preface</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography variant='body1'>
                                Personality and background are the most important part of character creation. Create a character that enables you to focus on the gameplay that you enjoy the most. Do you enjoy commanding the battlefield? Create a retired army soldier. Do you enjoy talking to people and trying to get yourself out of risky situations through cunning? Create a diplomat with a gambling addiction. Do you enjoy supporting other players and collecting and managing resources? Create a non-violent monk that studies herbology. While combat experience is not necessary, it is difficult to structure a campaign in such a way that avoids violence entirely, so it may be worthwhile to incorporate some amount of combat proficiency into your character design. The following sections will explain how to determine a character’s statistics.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>Name</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='body1'>
                                This is your character’s name. Other entities will refer to them by this name on occasion during Role-Playing and Combat periods.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>Culture</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Mechanics</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        Your character’s culture allows you to roll one additional die when attempting culturally relevant actions without any other relevant domain.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Explanation</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        Your character’s culture references the ideas, customs, and social behavior of the society they come from. Your character’s culture will give them an advantage when performing culturally relevant activities that your character is otherwise untrained in.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>Ability Matrix</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Mechanics</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        Ability-Cell values determine how many dice can be rerolled after attempting an activity related to the cell.
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        When creating a character, you will be allocated a number of Aptitude Points and Cell Points.
                                        <ul style={bulletStyle}>
                                            <li>For each Aptitude Point spent, increase the value of all Ability-Cells for an aptitude of your choice by one.</li>
                                            <li>For each Cell Point spent, increase the value of a single Ability-Cell of your choice by one.</li>
                                            <li>If no points are spent to increase the value of a cell, its value is zero.</li>
                                        </ul>
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        The rows of the Ability Matrix each represent one of three Aptitudes:
                                        <ul style={bulletStyle}>
                                            <li>Physical Aptitude represents a character’s strength, agility, and kinesthetic sense.</li>
                                            <li>Social Aptitude represents a character’s ability to notice, interpret, and control other’s feelings.</li>
                                            <li>Mental Aptitude represents a character’s memory, cognitive flexibility, and ability to focus.</li>
                                        </ul>
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        The columns of the Ability Matrix each represent one of three Traits:
                                        <ul style={bulletStyle}>
                                            <li>Power represents how forceful a character can be with aspects of the corresponding attribute.</li>
                                            <li>Finesse represents how manipulative or creative a character can be with aspects of the corresponding attribute.</li>
                                            <li>Perception represents how aware a character can be with aspects of the corresponding attribute.</li>
                                        </ul>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Explanation</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        Your character has a multitude of facets that determine how naturally capable they are of performing various tasks. The Ability Matrix abstracts these facets into measurable quantities which affect your character’s ability to succeed at relevant tasks. Two examples of tasks relevant to each cell of the Ability Matrix are shown below.
                                    </Typography>
                                    <TableContainer sx={bodyStyle} component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell><strong>POWER</strong></TableCell>
                                                    <TableCell><strong>FINESSE</strong></TableCell>
                                                    <TableCell><strong>PERCEPTION</strong></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell rowSpan={2}><strong>PHYSICAL</strong></TableCell>
                                                    <TableCell>Kicking down a door.</TableCell>
                                                    <TableCell>Balancing on a tightrope.</TableCell>
                                                    <TableCell>Being aware that one has been poisoned.</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Lifting a heavy object.</TableCell>
                                                    <TableCell>Playing a musical instrument.</TableCell>
                                                    <TableCell>Determining the temperature of water via touch.</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell rowSpan={2}><strong>SOCIAL</strong></TableCell>
                                                    <TableCell>Intimidating someone into running away.</TableCell>
                                                    <TableCell>Persuading someone to sell you something.</TableCell>
                                                    <TableCell>Knowing if someone is lying.</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Making a good impression on someone.</TableCell>
                                                    <TableCell>Lying to someone.</TableCell>
                                                    <TableCell>Identifying the leader of a group.</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell rowSpan={2}><strong>MENTAL</strong></TableCell>
                                                    <TableCell>Casting a spell.</TableCell>
                                                    <TableCell>Solving a puzzle.</TableCell>
                                                    <TableCell>Remembering the formula for a potion.</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Resisting the effects of mind control.</TableCell>
                                                    <TableCell>Tricking a mind reader with a false memory.</TableCell>
                                                    <TableCell>Remembering a creature’s vulnerability.</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Recommendations</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        We recommend three Aptitude Points, and six Cell Points for a player’s character. This creates characters that are more gifted than the average person, but not so exceptional that they would be apathetic to the dangers of the world.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>Health Pools</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Mechanics</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        Your character has three Health Pools that correspond to their three aptitudes. A character's Health Pools are equal to the sum of all the Ability-Cell values related to the corresponding aptitude plus three. A character starts with a number of Health Points equal to their Health Pool, and as they take damage, they lose Health Points.
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        When a character has less than one Health Point, but more Health Points than their negative Health Pool, they suffer from one of the following conditions based on the corresponding aptitude.
                                        <ul style={bulletStyle}>
                                            <li>Physical: The character is unconscious and their physical Health Points decrease by one every 3d6 rounded to the nearest five seconds, rolled at the beginning of unconsciousness, and again each time a physical Health Point is lost.</li>
                                            <li>Social: The character suffers from a random effect from the insanity table until their social Health Points return to their maximum value.</li>
                                            <li>Mental: The character is unconscious and the character’s mental Health Points increase by one every 1d6 minutes, rolled at the beginning of unconsciousness, and again each time a mental Health Point is gained.</li>
                                        </ul>
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        When a character has equal to or less Health Points than their negative Health Pool, they suffer from one of the following conditions based on the aptitude, and their Health Points can’t increase until the condition is removed.
                                        <ul style={bulletStyle}>
                                            <li>Physical: The character is dead.</li>
                                            <li>Social: The character continues to suffer from the insanity table effect they are currently affected by.</li>
                                            <li>Mental: The character is comatose.</li>
                                        </ul>
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        Every circadian cycle in which a character meets all of their needs, each Health Pool with fewer than the maximum number of Health Points gains one Health Point. If a character is unable to meet all of its needs for a full day, the maximum number of Health Points in each of its Health Pools is temporarily reduced by one for each consecutive day they have gone without meeting all of their needs.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Explanation</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        The Health Pools represent your character's capacity to withstand significant damage to their different faculties.
                                        <ul style={bulletStyle}>
                                            <li>Physical damage comes from sources that disrupt your character’s bodily functions.</li>
                                            <li>Social damage comes from sources that disrupt your character’s psychological well being.</li>
                                            <li>Mental damage comes from sources that disrupt your character’s central nervous system.</li>
                                        </ul>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Recommendations</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        If you want characters that are less vulnerable, increase the base Health Pool from three to a higher value. Nine would make players two to three times as tough as a normal person.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>Health Buffers</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Mechanics</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        Your character has three Health Buffers that correspond to their three aptitudes. A character's maximum Health Buffers are equal to the power Ability-Cell value of the corresponding aptitude plus three. When a character takes only a single point of damage, their Health Buffer is decreased by one. Otherwise, the damage is done to the Health Pool. When a Health Buffer reaches zero points, all following damage is done to the character’s corresponding Health Pool.
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        Every circadian cycle in which a character meets all of their needs, each Health Buffer returns to its maximum value. If a character is unable to meet all of its needs for a full day, their Health Buffer is not restored.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Explanation</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        The Health Buffers represent your character’s capacity to withstand small amounts of damage that can be recovered from quickly.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Recommendations</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        If you want characters that are less vulnerable, increase the base Health Buffer from three to a higher value. Six would make players two to three times as tough as a normal person.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>Domains</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Mechanics</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        Domain Tiers affect how many dice are rolled when attempting an activity relevant to the domain. A domain can be any general activity or skill your character is proficient in.
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        When creating a character you will be allocated a number of Domain Points.
                                        <ul style={bulletStyle}>
                                            <li>It costs one Domain Point to make your character tier one in a domain.</li>
                                            <li>It costs three Domain Points to make your character tier two in a domain.</li>
                                            <li>It costs six Domain Points to make your character tier three in a domain.</li>
                                        </ul>
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        There are three types of domains.
                                        <ul style={bulletStyle}>
                                            <li>Profession domains are for skills and activities your character makes a living with and would have social connections regarding.</li>
                                            <li>Hobby domains are for skills and activities your character does for fun or sport without profiting or developing social clout.</li>
                                            <li>Armament domains are specifically for weapons and other combat-related equipment.</li>
                                        </ul>
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        While profession and hobby domains are open ended, armament domains are restricted to one of the two following formats.
                                        <ul style={bulletStyle}>
                                            <li>An armament domain can be a specific weapon, object, or martial art. In this case, your character can apply their domain any time they attempt to use the domain in any way.</li>
                                            <li>An armament domain can be a weapon category. In this case your character can apply their domain only when using a weapon from the category as it was primarily intended to be used. However, a weapon category domain applies a smaller bonus to weapons from similar categories that your character would otherwise have no bonus to.</li>
                                        </ul>
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        Weapon category domains are formed by picking one from each of the following components.
                                        <ul style={bulletStyle}>
                                            <li>Weapon Size: Small, Medium, or Large</li>
                                            <li>Weapon Range: Melee, Thrown, or Projectile</li>
                                            <li>Damage Type: Piercing, Slashing, or Bashing</li>
                                        </ul>
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        If your character has a tier in a weapon category armament domain. If they attempt to use a weapon of a different size or damage type than the domain they have a tier in, you can apply your character’s Domain Tier after subtracting one for each component that is different. Weapon range components must match for this to apply.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Explanation</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        Your character has a multitude of activities and skills they have practiced throughout their life. Each of these is represented by a domain. While profession and hobby domains mostly affect role-playing and downtime periods, armament domains mostly affect combat periods. Your character only benefits mechanically from the domains for which you have spent points, but they can still attempt any activity regardless of its relevance to their domains. Three examples for each type of domain are shown below.
                                    </Typography>
                                    <TableContainer sx={bodyStyle} component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><strong>DOMAIN TYPE</strong></TableCell>
                                                    <TableCell><strong>EXAMPLES</strong></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Profession</TableCell>
                                                    <TableCell>Blacksmith, Hunting, Diplomat</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Hobby</TableCell>
                                                    <TableCell>Fishing, Exploring, Singing</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Armament (Specific)</TableCell>
                                                    <TableCell>Shortsword, Wrestling, Whip</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Armament (Category)</TableCell>
                                                    <TableCell>Medium-Melee-Bashing, Light-Thrown-Slashing, Heavy-Projectile-Piercing</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Typography sx={afterTableStyle} variant='body1'>
                                        Characters can be tier one, two, or three in a domain.
                                        <ul style={bulletStyle}>
                                            <li>Tier one indicates a character is completely proficient. A character that goes fishing every weekend for fun would have a tier one hobby domain in fishing.</li>
                                            <li>Tier two indicates a character is an expert. A local blacksmith nearing retirement would have a tier two profession domain in smithing.</li>
                                            <li>Tier three indicates a character is a master of their domain. A character trained from birth by warrior monks to become the ultimate fighter would have a tier three domain in martial arts.</li>
                                        </ul>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Recommendations</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        We recommend limiting players to a maximum of two or three professions. More than that seems unrealistic as most people don’t have more than two careers. If characters do lots of activities that make them money, focus on the social clout associated with the profession domains to narrow down which domains are professions and which are hobbies.
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        We recommend not allowing players to have an above tier three domain as they would become supernaturally gifted and nearly undefeatable in activities relevant to the domain.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>Instinct</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Mechanics</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        Your character’s instinct is the sum of all their armament Domain Tiers.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Explanation</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        Instinct represents your character’s ability to react quickly in dangerous situations. The amount of experience your character has training in armament domains gives them an edge over other entities at the beginning of combat periods.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>Movement Speed</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Mechanics</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        Your character’s total movement speed is equal to ten plus their physical-power Ability-Cell value plus their physical-finesse Ability-Cell value.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Explanation</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        Your character’s movement speed is mostly relevant during combat periods. Their movement speed determines how far they can move during combat and is based on both their strength and agility.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>Reaction Dice</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Mechanics</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        Your character's total Reaction Dice is equal to the sum of all their armament Domain Tiers.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Explanation</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        Reaction Dice represent your character’s capacity to respond to multiple threats at once. The amount of experience your character has training in armament domains allows them to effectively react to multiple threats during combat periods.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>Inventory</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography variant='body1'>
                                Your character should generally start with the weapons and equipment related to their domains and the situation they are in. However, the Storyteller and you can decide together what other starting equipment is appropriate for your character.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>Notes</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography variant='body1'>
                                The notes section can be used during character creation to describe your character and during adventures to keep track of details.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography variant='h4'>Role-Playing</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>Preface</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography variant='body1'>
                                Whenever your character attempts a non-trivial action or activity. You will have to roll dice to determine if your character succeeds. For trivial things, your character can succeed without a roll. For example, a geologist can identify all common rocks and minerals without needing to roll to see if they recognize the specimen, and anyone capable of running can hop over a short fence without needing to roll, but someone would have to roll to see if they remember a subtle piece of information from their past. All rolls made during role-playing periods adhere to the same formula, referred to as the Standard Roll. The Standard Roll and its variants are discussed in the following sections.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>The Standard Roll</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Mechanics</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        When you need to roll dice, you will decide with the Storyteller which, if any, of your character’s domains or culture is most relevant to the activity being attempted, and which Ability-Cell most applies. You then roll 3d6, which is referred to as a Base Roll, and then, if there was a relevant domain or culture, you add 1d6 to the roll if your character’s culture was relevant, or you add 1d6 for each tier in the relevant domain. You do not roll additional d6 for both a culture and a domain, domains supersede culture. After you have rolled all the allotted dice, you can, but do not have to, reroll a number of dice up to the value of your character’s relevant Ability-Cell. These rerolls must all be done at the same time, such that each die can only be rerolled at most one time. If a die is rerolled, you must keep the new value.
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        For your attempt to succeed, the total of your Standard Roll must be equal to or greater than an Opposing Roll. By default an Opposing Roll is a Base Roll. If you fail to roll equal to or higher than the opposing roll, your character doesn’t succeed.
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        If your Standard Roll is greater than the Opposing Roll by ten or more, it is considered a Tremendous Success. In this case, the Storyteller determines relevant auxiliary positive effects that occur in addition to your character’s success. On the other hand, if your Standard Roll is less than the Opposing Roll by ten or more, it is considered a Catastrophic Failure. In this case, the Storyteller determines relevant auxiliary negative effects that occur in addition to your character’s failure.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Explanation</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        The Standard Roll attempts to mimic a normal probability distribution in order for characters to demonstrate their average capabilities more often than their highest or lowest capabilities. By adding more dice to the roll as a character becomes more skilled in the task at hand, the mean of the roll increases and the variance decreases. This follows the notion that as an individual becomes more skilled, the quality of their outcomes not only increases, but also becomes more consistent.
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        Rerolling dice based on characters relevant Ability-Cell values will, given a fixed Ability-Cell value, have a more significant effect on the outcome when the player is rolling fewer dice, and a less significant, but still positive, effect on the outcome when the player is rolling more dice. This follows the notion that when an untrained individual attempts something, their natural capacity to perform relevant tasks will have a larger effect on the outcome than if they were skilled, in which case their training would be responsible for a majority of the effect. However, skilled individuals still benefit from natural talent, even if not as significantly as if they were unskilled.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Example</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        If your character is attempting to sneak up the side of a cobblestone tower, and has a climbing hobby Domain Tier of one, and a Physical-Power Ability-Cell value of two, you would roll 4d6 (3d6 + 1d6 for the Base Roll + Domain Tier) and then the Social-Finesse Ability-Cell value would allow you to reroll up to 2d6.
                                        <ul style={bulletStyle}>
                                            <li>A success would allow your character to climb the tower.</li>
                                            <li>A failure would result in your character failing to climb the tower.</li>
                                            <li>A Tremendous Success could result in your character climbing so quickly that it becomes harder for the guards to spot them.</li>
                                            <li>A Catastrophic Failure could result in your character falling and injuring themselves, or falling and making noise that alerts the guards to their presence.</li>
                                        </ul>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>Contested Rolls</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Mechanics</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        An Opposing Roll can be more than a Base Roll if an entity is actively trying to prevent the Standard Roll from succeeding. If this is the case, the Standard Roll is referred to as a Contested Roll. A contested roll is a Standard Roll where the Opposing Roll is made by an entity attempting to prevent the Standard Roll from succeeding. In this case, the opponents statistics are used to find a relevant domain and Ability-Cell value, and the same rules that determine the number of dice rolled and rerolled apply to the Opposing Roll. The same rules for determining if Standard Rolls succeed apply to Contested Rolls, the Opposing Roll is just made differently.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Explanation</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        The Contested Roll allows for situations where entities are actively working towards opposite outcomes. The same principles that apply to the Standard Roll apply again to the entity attempting to prevent the Standard Roll from succeeding, and therefore, the rolls made by both entities are of the same format.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Example</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        If your character is attempting to charm a bartender into giving them a discount, and has a diplomat profession Domain Tier of one, and a Social-Finesse Ability-Cell value of two, you would roll 4d6 (3d6 + 1d6 for the Base Roll + Domain Tier) and then the Social-Finesse Ability-Cell value would allow you to reroll up to 2d6. The Storyteller would then make a roll for the bartender, using their bartending profession Domain Tier and their Social-Perception Ability-Cell value.
                                        <ul style={bulletStyle}>
                                            <li>A success would allow your character to get a discount.</li>
                                            <li>A failure would result in the bartender rejecting your advances.</li>
                                            <li>A Tremendous Success could result in your character getting free drinks the whole night.</li>
                                            <li>A Catastrophic Failure could result in your character getting kicked out of the bar.</li>
                                        </ul>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>Advantages and Disadvantages</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Mechanics</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        When environmental situations make it easier or harder for your character to succeed, dice can be added or removed from your Standard Roll. Likewise, dice can be added or removed from the Opposing Roll of a Contested Roll if opponent-specific situations make it easier or harder for them to prevent your character from succeeding. The number of dice added or removed is determined by the Storyteller. Multiple advantageous or disadvantageous things can compound with each other or cancel each other out depending on the situation.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Explanation</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        Advantages and disadvantages allow for external factors to influence the success of entities' attempts in a way that facilitates easy attribution of causality.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Example</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        If your character was attempting to catch a fish, and you found a special fishing hole, that could apply an advantage to your Standard Roll to catch a fish. In this case you would roll an additional 1d6.
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        If you also had enchanted bait, you could stack another advantage and roll an additional 2d6 with your standard roll. Alternatively, if you were fishing in a poor location, but still had enchanted bait, the disadvantage from the poor location would cancel with the advantage from the bait, and you would roll an unmodified Standard Roll.
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        If the fish you were trying to catch was a magic fish that learns to avoid bait it's seen before, and everyone uses the same enchanted bait as you, then your roll would now be a Contested Roll, and the Opposing Roll would have an advantage.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography variant='h4'>Combat</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>Preface</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography variant='body1'>
                                During combat periods, the actions your character can take and the environment your character is fighting in are abstracted into categories of actions taken within specified time periods. At a high level, combat takes place in rounds, where each participant plans their turn, then takes actions and rolls dice to determine their success. The following sections explain how different aspects of combat have been abstracted to facilitate smooth, cohesive gameplay.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>Rounds</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Turn Order</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        Combat is partitioned into rounds, five second periods of in-game time, which repeat until combat ends. In each round, every combat participant gets one turn. To determine the turn order, each combat participant makes an Instinct Roll, which is a Base Roll plus additional dice equal to the participant's Instinct. Participants with higher rolls take their turn before participants with lower rolls until all participants have taken their turn, at which point the round ends. If participants' Instinct Rolls are equal, the participant with higher Instinct goes first. If these participants have equal Instinct, they reroll their Instinct Rolls to determine their turn order relative to each other. The turn order of a round persists as rounds are repeated during the same combat encounter.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Action Planning</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        At the beginning of a round, before any participant has taken their turn. Every participant plans their turn by secretly selecting up to two actions from the six actions discussed in the following section. This period of the round is referred to as the Planning Period. Players are allowed to briefly discuss with each other during the Planning Period, but must still select their character’s actions without explicitly revealing them to the other players. Participants controlled by the Storyteller also plan their turns during this time.
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        If both of a participant’s actions are the same action, this is referred to as focusing on an action, and instead of taking the action twice, the action is instead modified to be more effective. These modifications are discussed in the following Action sections.
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        After the Planning Period, participants begin to take their turns in the order determined by their Instinct Rolls. When it becomes a participant's turn, the actions they selected are revealed. If the participant no longer wishes to perform their two preselected actions, they can instead forgo their choices and take any single other action.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Reactions</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        During a round, when something conflicts with a participant’s interests, they can make a roll to ameliorate the conflict. While this roll would typically be the Opposing Roll of a Contested Roll, during combat periods, the Opposing Rolls become Reaction Rolls. Unlike a typical Standard Roll, a Reaction Roll consists of a Base Roll plus a number of Reaction Dice chosen by the participant. A participant cannot add more Reaction Dice to the Base Roll than the relevant Domain Tier of the Reaction Roll, nor can the participant add more dice to the Base Roll than they have available Reaction Dice. Like a Standard Roll, the participant may reroll a number of dice equal to the relevant Ability-Cell value. After a Reaction Roll, any dice rolled in addition to the Base Roll are removed from the participant’s available Reaction Dice. All entities have all their Reaction Dice at the start of combat periods and replenish all their Reaction Dice at the beginning of their turn. An entity can only spend Reaction Dice if they are aware of the potential threat, and are capable of physically reacting. Surprised, unconscious, or paralyzed targets can’t spend Reaction Dice, but their relevant Ability-Cell value still applies to their Reaction Roll.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>Actions</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Attack</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        When an entity takes the attack action, they attempt to inflict damage upon one or more targets within their weapon’s range. During the attack action, they are allocated a number of dice equal to the armament Domain Tier of the weapon they chose to attack with, these dice are referred to as Attack Dice. The entity then makes Attack Rolls, which are a Base Roll plus at least one attack die. Like a Standard Roll, the attack action’s relevant Ability-Cell value determines how many dice can be rerolled. Unlike a Standard Roll, the player choses how many Attack Dice they want to add to each Attack Roll. Therefore, during a single attack action, an entity could make one Attack Roll for each Attack Die they have, or make a single Attack Roll using all their Attack Dice at once, or make Attack Rolls with any other distribution of Attack Dice they see fit. If an entity focuses on the attack action, they are given one additional Attack Die.
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        When an entity makes an Attack Roll to damage a target. A Reaction Roll is made by the target. The difference between the attack roll and reaction roll determines the damage inflicted to the target. If the difference is ten or more, the attack is a Critical Hit, and something special happens at the Storyteller's discretion in addition to the damage dealt to the target. Critical Hits should strive to move the plot of the adventure forward in rewarding ways.
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        The size of the entity being attacked can add advantage or disadvantage to an Attack Roll. The size modifiers are shown in the table below.
                                    </Typography>
                                    <TableContainer sx={bodyStyle} component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><strong>Size Category</strong></TableCell>
                                                    <TableCell><strong>Modifier</strong></TableCell>
                                                    <TableCell><strong>Examples</strong></TableCell>
                                                </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                <TableRow>
                                                    <TableCell>Huge</TableCell>
                                                    <TableCell>+2d6</TableCell>
                                                    <TableCell>A fortification wall, The side of a barn, A windmill</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Large</TableCell>
                                                    <TableCell>+1d6</TableCell>
                                                    <TableCell>An elephant, A Car, A queen sized bed</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Medium</TableCell>
                                                    <TableCell>No Modifier</TableCell>
                                                    <TableCell>An adult human, A deer, A door</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Small</TableCell>
                                                    <TableCell>-1d6</TableCell>
                                                    <TableCell>A leg, A cat, A two-handed weapon</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Tiny</TableCell>
                                                    <TableCell>-2d6</TableCell>
                                                    <TableCell>A head, A hedgehog, A bottle</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Diminutive</TableCell>
                                                    <TableCell>-3d6</TableCell>
                                                    <TableCell>An eye, A ring, A small vial</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Typography sx={afterTableStyle} variant='body1'>
                                        There are many other factors that can add advantage or disadvantage to an Attack Roll or Reaction Roll. Some of the more common factors are shown in the table below.
                                    </Typography>
                                    <TableContainer sx={bodyStyle} component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><strong>Description</strong></TableCell>
                                                    <TableCell><strong>Modifier</strong></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Partially obscured vision</TableCell>
                                                    <TableCell>-1d6</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Heavily obscured vision</TableCell>
                                                    <TableCell>-2d6</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Range attack from significantly higher elevation</TableCell>
                                                    <TableCell>+1d6</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Range attack from significantly lower elevation</TableCell>
                                                    <TableCell>-1d6</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Attacking, maneuvering, or reacting in hazardous terrain</TableCell>
                                                    <TableCell>-1d6</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Attacking, maneuvering, or reacting while partially submerged in liquid</TableCell>
                                                    <TableCell>-1d6</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Attacking, maneuvering, or reacting while completely submerged in liquid</TableCell>
                                                    <TableCell>-2d6</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Attacking, maneuvering, or reacting while partially immobilized</TableCell>
                                                    <TableCell>-1d6</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Attacking, maneuvering, or reacting after fallen prone</TableCell>
                                                    <TableCell>-1d6</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Maneuver</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        When an entity takes the maneuver action, they are essentially making an attack, except the goal of the attack is to invoke a non-damage based effect upon the target, as opposed to inflicting damage. For example, disarming, pushing, restraining, or tripping a target. When an entity attempts a maneuver, they make Attack Rolls as if they were attacking, but upon a successful roll, if the equivalent attack would have done damage to the target’s Health Pool, the desired effect of the maneuver is applied to the target. If an entity focuses on the maneuver action, they are given one additional Maneuver Die, the equivalent to an Attack Die.
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        Maneuvers are not for attempts to sever limbs, decapitate, or otherwise do specific damage to targets. It is always the assumption that the player’s goal in attacking is to do maximum damage, so these types of effects can be used as flavor for particularly successful attack actions, not maneuvers.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Protect</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        When an entity takes the protect action, they specify an object or entity on or adjacent to them and a condition they wish to prevent, such as protecting another player from damage, protecting a statue from destruction, or preventing a doorway from being passed through. Until the beginning of the entity’s next turn, Reaction Rolls to prevent the specified conditions don’t remove Reaction Dice from the entity’s available reaction dice. If an entity focuses on this action, they have an advantage on any Reaction Roll used to prevent the specified conditions.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Move</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        When an entity takes the move action, they can either move their full movement speed in meters, or reorient themselves, for example, to stand up after falling or to mount or dismount another entity. Entities may have to make rolls to overcome obstacles during their movement, for example, jumping over a pool of lava or climbing up a rope. In this case, the movement action is taken regardless of whether or not the entity succeeds on their roll. If an entity focuses on the move action, they can choose between gaining an advantage on a roll required for their movement, or doubling their movement speed for the round.
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        The terrain and orientation of an entity can affect their movement speed. Common orientations and terrains and their effects on movement speed are shown in the table below.
                                    </Typography>
                                    <TableContainer sx={bodyStyle} component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><strong>Description</strong></TableCell>
                                                    <TableCell><strong>Modifier</strong></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Moving while crouching</TableCell>
                                                    <TableCell>1/2</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Moving while lying down</TableCell>
                                                    <TableCell>1/4</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Moving through knee to waist deep water</TableCell>
                                                    <TableCell>1/2</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Moving through higher than waist deep water</TableCell>
                                                    <TableCell>1/3</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Moving over slippery surface</TableCell>
                                                    <TableCell>1/2</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Moving over a very uneven surface</TableCell>
                                                    <TableCell>1/2</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Swimming</TableCell>
                                                    <TableCell>1 / (3 - relevant Domain Tier)</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Climbing</TableCell>
                                                    <TableCell>1 / (3 - relevant Domain Tier)</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Interact</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        When an entity takes the interact action, they can manipulate one object on or adjacent to them. For example, an entity can use the interact action to lock a door, tie a rope, load a ballista, stabilize a dying entity, or drink the contents of a vial. If an entity focuses on the interact action, they have an advantage on any roll required for their interact action. If no roll is required for the interact action, the entity can instead perform another interact action that also requires no roll.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Channel</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        When an entity takes the channel action, they are required to focus on the action, which allows them to channel a magical effect. Magical effects are unique and their details will be specified by their source.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>Equipment</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Weapons</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        A weapon is an object an entity can use to inflict damage. Weapons do damage after a successful attack, which removes Health Points from specific Health Pools or buffers. To determine how much damage is done to each Health Pool upon a successful attack, each weapon has a Weapon Card, which has a table that can be used to map the difference between an Attack Roll and a Reaction Roll to the damage inflicted to the target, as well as any unique information about the weapon.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Armor</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        Armor prevents damage from being taken by subtracting the armor's Damage Resistance from the damage taken from an attack before applying the damage to the wearer’s Health Pools or buffers. Each set or unique piece of armor has an Armor Card, which specifies the armor’s Damage Resistance for each damage type. In some circumstances, armor could have a negative Damage Resistance, in which case the damage taken would be amplified.
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        In addition to Damage Resistance, Armor Cards specify a Damage Vulnerability for each damage type and an overall Durability Value. If an entity wearing armor is hit with an attack and the damage done is equal to or greater than the armor’s Damage Vulnerability for the attack's particular damage type. The armor’s Durability Value is decreased by one. When an armor’s Durability Value is reduced to zero, the armor is too broken to use.
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        There are eight damage types that armor has the potential to protect against or be damaged by:
                                        <ul style={bulletStyle}>
                                            <li>Piercing damage comes from objects that pierce deep into the body.</li>
                                            <li>Slashing damage comes from objects that slice into the body.</li>
                                            <li>Bashing damage comes from objects that crush parts of the body.</li>
                                            <li>Concussive damage comes from the force done to the body by blast shockwaves.</li>
                                            <li>Heat damage comes from objects that burn the body.</li>
                                            <li>Cold damage comes from objects that freeze the body.</li>
                                            <li>Shock damage comes from electricity moving through the body.</li>
                                            <li>Corrosive damage comes from materials that chemically dissolve the body.</li>
                                        </ul>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>Causes of Damage</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Fall Damage</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        If an entity falls or jumps down from something, the distance they fall can deal damage to them. When an entity lands, they must make a Standard Roll to not get hurt versus a Height Roll, which is 1d6 for every meter the entity fell, and the entity takes bashing damage equivalent to the difference between the Height Roll and their Standard Roll.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Morale Damage</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        At the Storyteller’s discretion, A character with no tiers in an armament domain can take one point of social damage any time they are successfully attacked and particularly gruesome attacks can do social damage to onlookers.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>Combat Maps</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography variant='body1'>
                                At the Storyteller’s discretion, combat can take place on a combat map. In these maps, the space in which combat takes place is quantized into hexagons at various elevation levels. Each hexagon represents one meter. Therefore, each entity's movement speed is a measure of how many hexagons that entity can move through with a typical move action.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography variant='h4'>Downtime</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>Preface</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography variant='body1'>
                                During downtime periods, you may want your character to create things, develop skills, work, or do any other longer form tasks. While most of these activities should be done at the Storyteller’s discretion, taking into account available resources, teachers, the economy of the region, and other factors. There are rules specifically to facilitate collecting resources and crafting with them.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant='h5'>Collecting and Crafting</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Mechanics</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        When you want your character to collect resources or craft something during downtime, a Crafting Role is made. A Crafting Roll is a Standard Roll made using a domain and Ability-Cell relevant to the resource being collected or object being crafted, but similar to an Attack Roll, the difference between your roll and the Opposing Roll determines the magnitude of your success. Every item that can be collected or created has a Point Quantity. If your Standard Roll is higher than the Opposing Roll, the difference between the two rolls multiplied by the Point Quantity of the object determines the quantity collected or created.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Explanation</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        Separating the threshold for success from the amount of resources collected allows for the rarity of the item to be independent of the quantity of items gathered or created. This allows for a wider variety of items.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography variant='h6'>Example</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body1'>
                                        A particular mushroom might be small and scarce, therefore there is a -2d6 disadvantage to the Crafting Roll made to collect the mushroom. However, This mushroom is typically found in large clusters, therefore its point quantity is ten. If you rolled a seven and the Opposing Roll was five, you would have found twenty mushrooms.
                                    </Typography>
                                    <Typography sx={bodyStyle} variant='body1'>
                                        Alternatively, trying to carve a canoe with a particularly workable wood might have a +1d6 advantage, but it is very time consuming, therefore the canoe could have a point quantity of 1/30. So even if you rolled fifteen higher than the opposing roll, you would only be half-way done with the canoe.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionDetails>
                    </Accordion>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}
