import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

export default function Notes(props)
{

    const boxStyle = {
        padding: 0,
        width: '100%',
        minHeight: props.bigScreen ? 'calc(100vh - 144px)' : 'calc(100vh - 136px)',
        marginTop: props.bigScreen ? '76px' : '68px',
        marginBottom: '68px',
        border: '1.5px solid black',
        pointerEvents: props.editable ? 'auto' : 'none',
    }

    const fieldStyle = {
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingLeft: '10px',
        paddingRight: '10px',
    }

    return(
        <Box sx={boxStyle}>
            <TextField
                fullWidth
                sx={fieldStyle}
                multiline={true}
                variant='standard'
                size='small'
                placeholder='Tap here to start taking notes.'
                value={props.characterData['notes']}
                onChange={(e) => {props.editCharacterData('notes', e.target.value)}}
                InputProps={{disableUnderline: true}}
            />
        </Box>
    )
}
