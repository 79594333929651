import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import MenuIcon from '@mui/icons-material/Menu'
import SaveIcon from '@mui/icons-material/Save'
import EditIcon from '@mui/icons-material/Edit'

import {Auth, API} from 'aws-amplify'

import Loading from '../utils/Loading'

function CharacterIcons(props) {

    const iconStyle = {
        paddingRight: '10px',
    }

    const buttonStyle = {
        padding: '0px',
        borderWidth: 0,
        color: props.editable ? '#347526' : 'inherit',
    }

    const [loading, setLoading] = React.useState(false)

    const handleSave = async () => {
        setLoading(true)
        await API.post(
            'UserContentAPI',
            `/users/${props.user.username}/characters/${props.characterId}`,
            {body: props.characterData}
        )
        props.setSaved(true)
        setLoading(false)
    }

    return (
        <>
            {!props.saved && (
                <IconButton
                    color='inherit'
                    onClick={() => {handleSave()}}
                    sx={iconStyle}
                >
                    <SaveIcon/>
                </IconButton>
            )}

            <IconButton
                color={props.editable ? '#00FF00' : 'inherit'}
                onClick={() => {
                    props.setEditable(!props.editable)
                    props.setSelectedKeys([])
                    props.setRollReady(false)
                }}
                sx={buttonStyle}
            >
                <EditIcon/>
            </IconButton>
            <Loading open={loading}/>
        </>
    )
}

export default function NavigationBar(props) {

    const iconStyle = {
        padding: '10px',
    }

    const headerStyle = {
        flexGrow: 1,
        overflow: 'hidden',
    }

    const [anchorEl, setAnchorEl] = React.useState(null)
    const [error, setError] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    const menuOptions = () => {
        return props.user ? [
            'Home',
            'Rules',
            'divider',
            'Account Management',
            'Sign Out',
        ] : [
            'Home',
            'Rules',
        ]
    }

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const handleMenuItemClick = async (item) => {
        if (item === 'Sign Out')
        {
            setLoading(true)
            try {
                await Auth.signOut()
                props.setUser(null)
                props.setCharacterIds([])
                props.setCharacterId(null)
                props.setPage('Home')
                props.setCharacterData(null)
                setLoading(false)
            }
            catch (error) {
                setLoading(false)
                setError(error.message)
            }
        }
        else
        {
            props.setCharacterId(null)
            props.setCharacterData(null)
            props.setPage(item)
        }
        handleMenuClose()
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position='fixed'>
                <Toolbar>

                    <IconButton
                        edge='start'
                        color='inherit'
                        onClick={handleMenuOpen}
                        sx={iconStyle}
                    >
                        {<MenuIcon/>}
                    </IconButton>

                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        {menuOptions().map((text) =>
                            text === 'divider' ? (
                                <Divider key={text} sx={{width: '90%', margin: 'auto',}}/>
                            ) : (
                                <MenuItem
                                    key={text}
                                    sx={{minWidth: '192px'}}
                                    onClick={() => handleMenuItemClick(text)}
                                >
                                    {text}
                                </MenuItem>
                            )
                        )}
                    </Menu>

                    <Typography variant='h6' component='div' sx={headerStyle}>
                        {props.characterId ? props.characterId : props.page}
                    </Typography>

                    {props.characterId && (<CharacterIcons {...props}/>)}

                </Toolbar>
            </AppBar>

            {error && (
                <Snackbar open={true} autoHideDuration={6000} onClose={() => setError(null)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                    <Alert
                        sx={{width: '100%'}}
                        severity='error'
                        onClose={() => setError(null)}>
                        {error}
                    </Alert>
                </Snackbar>
            )}
            <Loading open={loading}/>
        </Box>
    )
}
