import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'

import Loading from '../../utils/Loading'

import {API} from 'aws-amplify'

export default function LoadCharacter(props) {

    const [characterId, setCharacterId] = React.useState('')
    const [loading, setLoading] = React.useState(false)

    const handleClose = () => {
        setCharacterId('')
        props.setOpen(false)
    }

    const handleLoad = async () => {
        setLoading(true)
        const characterData = await API.get(
            'UserContentAPI',
            `/users/${props.user.username}/characters/${characterId}`
        )
        props.setCharacterId(characterId)
        props.setCharacterData(characterData)
        props.setPage('Statistics')
        props.setSelectedKeys([])
        props.setRollReady(false)
        props.setActions([[0, ''], [1, '']])
        props.setRollValue(1)
        props.setRerollValue(0)
    }

    return (
        <Dialog
            sx={{alignContent: ''}}
            PaperProps={{style: {width: '100vw'}}}
            open={props.open}
            onClose={handleClose}
        >
            <DialogTitle sx={{textAlign: 'center'}}>
                Load Character
            </DialogTitle>
            <DialogContent>
                <Select
                    fullWidth
                    autoFocus
                    margin='dense'
                    value={characterId}
                    onChange={(e) => setCharacterId(e.target.value)}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: '256px',
                                overflow: 'auto',
                            },
                        },
                    }}
                >
                    {props.characterIds.map((id) => (
                        <MenuItem key={id} value={id}>
                            {id}
                        </MenuItem>
                    ))}
                </Select>
            </DialogContent>
            <DialogActions sx={{paddingLeft: 1.5, paddingRight: 1.5, justifyContent: 'space-between'}}>
                <Button
                    onClick={handleClose}
                    color='primary'
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleLoad}
                    color='primary'
                    disabled={characterId === ''}
                >
                    Load
                </Button>
            </DialogActions>
            <Loading open={loading}/>
        </Dialog>
    )
}