import * as React from 'react'
import Container from '@mui/material/Container'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import NavigationBar from './navigation/NavigationBar'
import CharacterNavigation from './navigation/CharacterNavigation'

import Home from './home/Home'

import AccountManagement from './accountManagement/AccountManagement'
import ForgotPassword from './accountManagement/ForgotPassword'
import CreateAccount from './accountManagement/CreateAccount'

import Statistics from './userContent/characterSheet/statistics/Statistics'
import Inventory from './userContent/characterSheet/inventory/Inventory'
import Notes from './userContent/characterSheet/notes/Notes'
import Play from './userContent/characterSheet/play/Play'

import Rules from './gameContent/Rules'

import Loading from './utils/Loading'

import {Auth, API} from 'aws-amplify'

const theme = createTheme({
  palette: {
    primary: {
      main: '#ad987f',
    },
    secondary: {
      main: '#ad987f',
    }
  }
})

export default function App() {

  const backgroundPages = [
    'Home',
    'Rules',
    'Forgot Password',
    'Create Account',
    'Account Management',
  ]

  const backgroundStyle = {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    backgroundImage: 'url(world_map.jpg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    overflowX: 'hidden',
    overflowY: 'auto'
  }

  const whiteStyle = {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#ffffff',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    overflowX: 'hidden',
    overflowY: 'auto'
  }

  const bigScreen = useMediaQuery('(min-width:600px)')

  const [user, setUser] = React.useState(null)
  const [characterIds, setCharacterIds] = React.useState([])
  const [characterId, setCharacterId] = React.useState(null)
  const [characterData, setCharacterData] = React.useState(null)
  const [page, setPage] = React.useState('Home')
  const [saved, setSaved] = React.useState(true)
  const [editable, setEditable] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const [selectedKeys, setSelectedKeys] = React.useState([])
  const [rollReady, setRollReady] = React.useState(false)
  const [actions, setActions] = React.useState([[0, ''], [1, '']])
  const [rollValue, setRollValue] = React.useState(1)
  const [rerollValue, setRerollValue] = React.useState(0)

  const currentPage = () => {
    switch(page) {
      case 'Home': return <Home
        bigScreen={bigScreen}
        user={user}
        setUser={setUser}
        characterIds={characterIds}
        setCharacterIds={setOrderedCharacterIds}
        setCharacterId={setCharacterId}
        setCharacterData={setCharacterData}
        setPage={setPage}
        setSelectedKeys={setSelectedKeys}
        setRollReady={setRollReady}
        setActions = {setActions}
        setRollValue = {setRollValue}
        setRerollValue = {setRerollValue}
      />
      case 'Forgot Password': return <ForgotPassword
        bigScreen={bigScreen}
        setUser={setUser}
        setCharacterIds={setOrderedCharacterIds}
        setPage={setPage}
      />
      case 'Create Account': return <CreateAccount
        bigScreen={bigScreen}
        setUser={setUser}
        setCharacterIds={setOrderedCharacterIds}
        setPage={setPage}
      />
      case 'Account Management': return <AccountManagement
        bigScreen={bigScreen}
        user={user}
        setUser={setUser}
        setCharacterIds={setCharacterIds}
        setPage={setPage}
      />
      case 'Statistics': return <Statistics
        bigScreen={bigScreen}
        characterData={characterData}
        editCharacterData={editCharacterData}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        rollReady={rollReady}
        setRollReady={setRollReady}
        editable={editable}
      />
      case 'Inventory': return <Inventory
        bigScreen={bigScreen}
        characterData={characterData}
        editCharacterData={editCharacterData}
        editable={editable}
      />
      case 'Notes': return <Notes
        bigScreen={bigScreen}
        characterData={characterData}
        editCharacterData={editCharacterData}
        editable={editable}
      />
      case 'Play': return <Play
        bigScreen={bigScreen}
        actions = {actions}
        setActions = {setActions}
        rollValue = {rollValue}
        setRollValue = {setRollValue}
        rerollValue = {rerollValue}
        setRerollValue = {setRerollValue}
      />
      case 'Rules': return <Rules
        bigScreen={bigScreen}
      />
      default: return <div/>
    }
  }

  const setOrderedCharacterIds = (ids) => {
    setCharacterIds(ids.sort())
  }

  const editCharacterData = (key, value) => {
    setCharacterData(prevCharacterData => ({
      ...prevCharacterData,
      [key]: value
    }))
    setSaved(false)
  }

  const autoSignIn = async (user) => {
    setLoading(true)
    try {
        const characterIds = await API.get(
          'UserContentAPI',
          `/users/${user.username}/getCharacters`
        )
        setUser(user)
        setCharacterIds(characterIds)
    }
    catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  React.useEffect(() => {Auth.currentAuthenticatedUser().then((user) => {autoSignIn(user)}).catch((error) => {})}, [])

  return (
    <ThemeProvider theme={theme}>
      <div style={backgroundStyle}>
        <div style={backgroundPages.includes(page) ? {} : whiteStyle}>
          <NavigationBar
            user={user}
            setUser={setUser}
            setCharacterIds={setCharacterIds}
            characterId={characterId}
            setCharacterId={setCharacterId}
            characterData={characterData}
            setCharacterData={setCharacterData}
            page={page}
            setPage={setPage}
            saved={saved}
            setSaved={setSaved}
            editable={editable}
            setEditable={setEditable}
            setSelectedKeys={setSelectedKeys}
            setRollReady={setRollReady}
          />


          <Container maxWidth={page === 'Rules' ? 'lg' : 'xs'}>
            {currentPage()}
          </Container>

          {characterId && (<CharacterNavigation page={page} setPage={setPage}/>)}

          <Loading open={loading} message='Signing you back in.'/>
        </div>
      </div>
    </ThemeProvider>
  )
}
