import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import Loading from '../../utils/Loading'

import {Auth, API} from 'aws-amplify'

export default function VerifyAccount(props) {

    const [verificationCode, setVerificationCode] = React.useState('')
    const [error, setError] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    const handleSubmit = async () => {
        setLoading(true)
        try {
            await Auth.confirmSignUp(props.email, verificationCode)
            const user = await Auth.signIn(props.email, props.password)
            await API.post('UserContentAPI', `/users/${user.username}`)
            props.setUser(user)
            props.setCharacterIds([])
            props.setPage('Home')
        }
        catch (error) {
            setError(error.message)
        }
        setLoading(false)
    }

  return (
    <Dialog
        sx={{alignContent: ''}}
        PaperProps={{style: {width: '100vw'}}}
        open={props.open}
    >
        <DialogTitle sx={{textAlign: 'center'}}>
            Verify Account
        </DialogTitle>
        <DialogContent>
            <TextField
                fullWidth
                autoFocus
                margin='dense'
                label='Verification Code'
                type='text'
                helperText='A code was just emailed to you.'
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}

            />
        </DialogContent>
        <DialogActions sx={{paddingTop: 0, paddingLeft: 1.5, paddingRight: 1.5, justifyContent: 'center'}}>
            <Button
                onClick={handleSubmit}
                color='primary'
                disabled={verificationCode.length !== 6}
            >
                Verify Account
            </Button>
        </DialogActions>

        {error && (
            <Snackbar open={true} autoHideDuration={6000} onClose={() => setError(null)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert
                    sx={{width: '100%'}}
                    severity='error'
                    onClose={() => setError(null)}>
                    {error}
                </Alert>
            </Snackbar>
        )}
        <Loading open={loading}/>
    </Dialog>
  )
}
