import * as React from 'react'
import Grid from '@mui/material/Grid'

import InformationTable from './components/InformationTable'
import AbilityMatrix from './components/AbilityMatrix'
import HealthTable from './components/HealthTable'
import CombatTable from './components/CombatTable'
import DomainTable from './components/DomainTable'

import RollDial from '../overlays/RollDial'

export default function Statistics(props) {

    return (
        <div>
            <Grid
                container
                spacing={1}
                columns={1}
                style={{marginTop: props.bigScreen ? '64px' : '56px', marginBottom: '74px'}}
            >

                <Grid item xs={1}>
                    <InformationTable
                        editable={props.editable}
                        characterData={props.characterData}
                        editCharacterData={props.editCharacterData}
                        selectedKeys={props.selectedKeys}
                        setSelectedKeys={props.setSelectedKeys}
                        setRollReady={props.setRollReady}
                    />
                </Grid>

                <Grid item xs={1}>
                    <div style={{display: 'flex', alignItems: 'stretch'}}>
                        <div style={{flex: '1 1 63.6%'}}>
                            <AbilityMatrix
                                editable={props.editable}
                                characterData={props.characterData}
                                editCharacterData={props.editCharacterData}
                                selectedKeys={props.selectedKeys}
                                setSelectedKeys={props.setSelectedKeys}
                                setRollReady={props.setRollReady}
                            />
                        </div>
                        <div style={{flex: '1 1 36.4%'}}>
                            <HealthTable
                                editable={props.editable}
                                characterData={props.characterData}
                                editCharacterData={props.editCharacterData}
                            />
                        </div>
                    </div>
                </Grid>

                <Grid item xs={1}>
                    <CombatTable
                        editable={props.editable}
                        characterData={props.characterData}
                        editCharacterData={props.editCharacterData}
                        selectedKeys={props.selectedKeys}
                        setSelectedKeys={props.setSelectedKeys}
                        setRollReady={props.setRollReady}
                    />
                </Grid>

                <Grid item xs={1}>
                    <DomainTable
                        editable={props.editable}
                        characterData={props.characterData}
                        editCharacterData={props.editCharacterData}
                        selectedKeys={props.selectedKeys}
                        setSelectedKeys={props.setSelectedKeys}
                        setRollReady={props.setRollReady}
                    />
                </Grid>

            </Grid>

            {props.rollReady && (
                <RollDial
                    characterData={props.characterData}
                    selectedKeys={props.selectedKeys}
                />
            )}
        </div>
    )
}
