import * as React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import Label from '../../utils/Label'
import Field from '../../utils/Field'
import SelectableField from '../../utils/SelectableField'

export default function AbilityMatrix(props)
{
    const aptutides = [
        'PHY\u00A0',
        'SOC\u00A0',
        'MEN\u00A0',
    ]

    const traits = [
        'POW',
        'FIN',
        'PER',
    ]

    const abilityKeys = [
        'physicalPower',
        'physicalFinesse',
        'physicalPerception',
        'socialPower',
        'socialFinesse',
        'socialPerception',
        'mentalPower',
        'mentalFinesse',
        'mentalPerception',
    ]

    const fieldStyle = {
        padding: 0,
        aspectRatio: '1/1',
        borderRadius: 0,
        border: '1px solid black',
    }

    const borderStyle = {
        border: '1.5px solid black',
    }

    const aptitudeStyle = {
        height: '100%',
    }

    return(
        <Grid container spacing={0} columns={7}>

            <Grid item xs={1}/>

            <Grid item xs={6}>
                <Grid container spacing={0} columns={3}>
                    {traits.map((label) => (
                        <Grid item xs={1} key={label}>
                            <Label
                                justify='center'
                                fontSize={11}
                                label={label}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            <Grid item xs={1}>
                <Grid container spacing={0} columns={1} sx={aptitudeStyle}>
                    {aptutides.map((label) => (
                        <Grid item xs={1} key={label}>
                            <Label
                                justify='right'
                                fontSize={11}
                                label={label}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            <Grid item xs={6}>
                <Grid container spacing={0} columns={3} sx={borderStyle}>
                    {abilityKeys.map((characterKey) => (
                        <Grid item xs={1} key={characterKey}>
                            <Box sx={fieldStyle}>
                                <SelectableField
                                    editable={props.editable}
                                    multiline={false}
                                    formatted={false}
                                    fontSize={32}
                                    type='int'
                                    characterKey={characterKey}
                                    characterData={props.characterData}
                                    editCharacterData={props.editCharacterData}
                                    selectedKeys={props.selectedKeys}
                                    setSelectedKeys={props.setSelectedKeys}
                                    setRollReady={props.setRollReady}
                                />
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    )
}