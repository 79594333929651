import * as React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import VerifyAccount from './overlays/VerifyAccount'
import Loading from '../utils/Loading'

import {Auth, API} from 'aws-amplify'

export default function SignIn(props) {

    const boxStyle = {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }

    const cardStyle = {
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.75)',
    }

    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [verifyAccountOpen, setVerifyAccountOpen] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    const handleSubmit = async () => {
        setLoading(true)
        try {
            const user = await Auth.signIn(email, password)
            const characterIds = await API.get(
                'UserContentAPI',
                `/users/${user.username}/getCharacters`
            )
            props.setUser(user)
            props.setCharacterIds(characterIds)
        }
        catch (error) {
            if (error.code === 'UserNotConfirmedException') {
                try {
                    await Auth.resendSignUp(email)
                    setVerifyAccountOpen(true)
                } catch (error) {
                    setError(error.message)
                }
            }
            else {
                setError(error.message)
            }
        }
        setLoading(false)
    }

    return (
        <Box sx={boxStyle}>
            <Card sx={cardStyle}>
                <Box sx={{margin: 1}}>
                    <TextField
                        sx={{marginTop: 0.7}}
                        required
                        fullWidth
                        autoFocus
                        margin='normal'
                        label='Email'
                        type='email'
                        value={email}
                        onChange={(e) => {setEmail(e.target.value)}}
                    />
                    <TextField
                        required
                        fullWidth
                        margin='normal'
                        label='Password'
                        type='password'
                        value={password}
                        onChange={(e) => {setPassword(e.target.value)}}
                    />
                    <Button
                        fullWidth
                        type='submit'
                        variant='contained'
                        sx={{marginTop: 1.5, marginBottom: 2}}
                        onClick={() => {handleSubmit()}}
                        disabled={(email === '') || (password === '')}
                    >
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link
                                href='#'
                                variant='body2'
                                onClick={() => {props.setPage('Forgot Password')}}
                            >
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link
                                href='#'
                                variant='body2'
                                onClick={() => {props.setPage('Create Account')}}
                            >
                                Need an account? Sign up.
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Card>

            <VerifyAccount
                open={verifyAccountOpen}
                setOpen={setVerifyAccountOpen}
                email={email}
                password={password}
                setUser={props.setUser}
                setCharacterIds={props.setCharacterIds}
                setPage={props.setPage}
            />
            {error && (
                <Snackbar open={true} autoHideDuration={6000} onClose={() => setError(null)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                    <Alert
                        sx={{width: '100%'}}
                        severity='error'
                        onClose={() => setError(null)}>
                        {error}
                    </Alert>
                </Snackbar>
            )}
            <Loading open={loading}/>
        </Box>
    )
}
