import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

export default function Roll(props) {

    const [roll, setRoll] = React.useState('')

    React.useEffect(() => {handleOpen()}, [props.open])

    const handleOpen = () => {
        if (props.open)
        {
            let rolls = []
            let rerollCount = 0
            let total = 0
            for (let i = 0; i < props.rollValue; i++)
            {
                rolls.push(parseInt(Math.random() * 6 + 1))
            }
            rolls.sort()
            for (let i = 0; i < props.rollValue; i++)
            {
                if((rolls[i] < 4) && (rerollCount < props.rerollValue))
                {
                    rolls[i] = parseInt(Math.random() * 6 + 1)
                    rerollCount = rerollCount + 1
                }
                total = total + rolls[i]
            }
            setRoll(total)
        }
    }

    const handleClose = () => {
        setRoll('')
        props.setOpen(false)
    }

    return (
        <Dialog
            sx={{alignContent: ''}}
            PaperProps={{style: {width: '200px'}}}
            open={props.open}
        >
            <DialogTitle sx={{textAlign: 'center'}}>
                Roll Total:
            </DialogTitle>
            <DialogContent>
                <Typography variant='h3' align='center'>
                    {roll}
                </Typography>
            </DialogContent>
            <DialogActions sx={{paddingTop: 0, paddingLeft: 1.5, paddingRight: 1.5, justifyContent: 'center'}}>
                <Button
                    onClick={handleClose}
                    color='primary'
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}