import * as React from 'react'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

export default function GameContent(props) {

    const boxStyle = {
        marginTop: 3,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }

    const cardStyle = {
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.75)',
    }

    return (
        <Box sx={boxStyle}>
            <Card sx={cardStyle}>
                <Box sx={{margin: 1.5}}>
                    <Button
                        fullWidth
                        type='submit'
                        variant='contained'
                        sx={{marginTop: 0}}
                        onClick={() => {props.setPage('Rules')}}
                    >
                        Rules
                    </Button>
                </Box>
            </Card>
        </Box>
    )
}
