import * as React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import Label from '../../utils/Label'
import Field from '../../utils/Field'
import SelectableField from '../../utils/SelectableField'

function RatioBlock(props)
{

    const cellStyle = {
        padding: '5%',
        border: '1.5px solid black',
        backgroundImage: 'url(half_slash.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }

    const itemStyle = {
        width: '100%',
        aspectRatio: '1/1',
    }

    const fieldStyle = {
        width: '100%',
        aspectRatio: '1/1',
        borderRadius: '50%',
        border: '1.5px solid black',
    }

    return(
        <Box sx={cellStyle}>
            <Grid container spacing={2} columns={2}>
                <Grid item xs={1} sx={itemStyle}>
                    <Box sx={fieldStyle}>
                        <Field
                            multiline={false}
                            formatted={false}
                            fontSize={16}
                            type='int'
                            characterKey={props.currentKey}
                            characterData={props.characterData}
                            editCharacterData={props.editCharacterData}
                        />
                    </Box>
                </Grid>
                <Grid item xs={1} sx={itemStyle}>
                    <Box sx={fieldStyle}>
                        <Field
                            editable={props.editable}
                            multiline={false}
                            formatted={false}
                            fontSize={16}
                            type='int'
                            characterKey={props.totalKey}
                            characterData={props.characterData}
                            editCharacterData={props.editCharacterData}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default function CombatTable(props)
{

    const rimStyle = {
        padding: '5%',
    }

    const fieldStyle = {
        width: '100%',
        aspectRatio: '1/1',
        borderRadius: '50%',
        border: '1.5px solid black',
    }

    return(
        <Grid container rowSpacing={0} columnSpacing={1.5} columns={7}>

            <Grid item xs={1}/>
            <Grid item xs={1}>
                <Label
                    justify='center'
                    fontSize={12}
                    label='Instinct'
                />
            </Grid>
            <Grid item xs={2}>
                <Label
                    justify='center'
                    fontSize={12}
                    label='Movement'
                />
            </Grid>
            <Grid item xs={2}>
                <Label
                    justify='center'
                    fontSize={12}
                    label='Reaction'
                />
            </Grid>
            <Grid item xs={1}/>

            <Grid item xs={1}/>
            <Grid item xs={1}>
                <Box sx={rimStyle}>
                    <Box sx={fieldStyle}>
                        <SelectableField
                            editable={props.editable}
                            multiline={false}
                            formatted={false}
                            fontSize={20}
                            type='int'
                            characterKey='instinct'
                            characterData={props.characterData}
                            editCharacterData={props.editCharacterData}
                            selectedKeys={props.selectedKeys}
                            setSelectedKeys={props.setSelectedKeys}
                            setRollReady={props.setRollReady}
                        />
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <RatioBlock
                    editable={props.editable}
                    currentKey='currentReactionDice'
                    totalKey='totalReactionDice'
                    characterData={props.characterData}
                    editCharacterData={props.editCharacterData}
                />
            </Grid>
            <Grid item xs={2}>
                <RatioBlock
                    editable={props.editable}
                    currentKey='currentMovementSpeed'
                    totalKey='totalMovementSpeed'
                    characterData={props.characterData}
                    editCharacterData={props.editCharacterData}
                />
            </Grid>
            <Grid item xs={1}/>

        </Grid>
    )
}
