import * as React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import SetPassword from './overlays/SetPassword'
import Loading from '../utils/Loading'

import {Auth} from 'aws-amplify'

export default function ForgotPassword(props) {

    const boxStyle = {
        marginTop: props.bigScreen ? '90px' : '82px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }

    const cardStyle = {
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.75)',
    }

    const [email, setEmail] = React.useState('')
    const [setPasswordOpen, setSetPasswordOpen] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    const handleSubmit = async () => {
        setLoading(true)
        try {
            await Auth.forgotPassword(email)
            setSetPasswordOpen(true)
        }
        catch (error) {
            setLoading(false)
            setError(error.message)
        }
    }

    return (
        <Box sx={boxStyle}>
            <Card sx={cardStyle}>
                <Box sx={{margin: 1}}>
                    <TextField
                        sx={{marginTop: 0.7}}
                        required
                        fullWidth
                        autoFocus
                        margin='normal'
                        label='Email'
                        type='email'
                        value={email}
                        onChange={(e) => {setEmail(e.target.value)}}
                    />
                    <Button
                        fullWidth
                        type='submit'
                        variant='contained'
                        sx={{marginTop: 3, marginBottom: 2}}
                        onClick={() => {handleSubmit()}}
                        disabled={(email === '')}
                    >
                        Request Recovery Code
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link
                                href='#'
                                variant='body2'
                                onClick={() => {props.setPage('Home')}}
                            >
                                Remembered your password? Sign In.
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Card>

            <SetPassword
                open={setPasswordOpen}
                email={email}
                setUser={props.setUser}
                setCharacterIds={props.setCharacterIds}
                setPage={props.setPage}
            />
            {error && (
                <Snackbar open={true} autoHideDuration={6000} onClose={() => setError(null)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                    <Alert
                        sx={{width: '100%'}}
                        severity='error'
                        onClose={() => setError(null)}>
                        {error}
                    </Alert>
                </Snackbar>
            )}
            <Loading open={loading}/>
        </Box>
    )
}
