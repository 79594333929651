import React from 'react'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import Typography from '@mui/material/Typography'

import {GiCubes} from 'react-icons/gi'
import {BsFillCheckCircleFill} from 'react-icons/bs'
import {FaDiceD6} from 'react-icons/fa'

import Roll from './Roll'

function Icon(props) {

    const textStyle = {
        position: 'relative',
        top: '-1px',
        left: '1.5px',
        padding: '3px',
        fontSize: '10px',
    }

    const iconStyle = {
        position: 'relative',
        top: '3px',
        right: '3px',
        fontSize: '14px',
    }

    return (
        <div>
            <Typography sx={textStyle}>
                {props.label} <FaDiceD6 style={iconStyle}/>
            </Typography>
        </div>
    )
}

export default function RollDial(props) {

    const actionsUpwards = ['+1', '+2', '+3']
    const actionsDownwards = ['-1', '-2', '-3']

    const dialStyle = {
        position: 'fixed',
        right: '8px',
    }

    const actionStyle = {
        color: 'black',
        backgroundColor: '#ad987f',
        border: '1px solid black',
    }

    const [open, setOpen] = React.useState(false)
    const [rollOpen, setRollOpen] = React.useState(false)
    const [rollValue, setRollValue] = React.useState(0)
    const [rerollValue, setRerollValue] = React.useState(0)

    const handleRoll = (mod) => {
        const base = 3 + parseInt(mod)
        if (props.selectedKeys[0] === 'instinct')
        {
            setRollValue(props.characterData['instinct'] + base)
            setRerollValue(0)
        }
        else if (props.selectedKeys.includes('culture'))
        {
            const abilityCell = props.selectedKeys.filter(item => item !== 'culture')[0]
            setRollValue(1 + base)
            setRerollValue(props.characterData[abilityCell])
        }
        else if (props.selectedKeys.filter(item => item.includes('domain')).length === 1)
        {
            const abilityCell = props.selectedKeys.filter(item => !item.includes('domain'))[0]
            const domain = props.selectedKeys.filter(item => item.includes('domain'))[0].replace('Name', 'Tier')
            setRollValue(props.characterData[domain] + base)
            setRerollValue(props.characterData[abilityCell])
        }
        else
        {
            const abilityCell = props.selectedKeys[0]
            setRollValue(base)
            setRerollValue(props.characterData[abilityCell])
        }
        setRollOpen(true)
        setOpen(false)
    }

    return (
        <div>

            <SpeedDial
                ariaLabel='up'
                direction='up'
                sx={{...dialStyle, bottom: '50%', transform: 'translateY(+11.7%)'}}
                icon={
                    open ?
                    <BsFillCheckCircleFill fontSize='30px'/> :
                    <GiCubes fontSize='30px'/>
                }
                open={open}
                onClick={() => {
                    if(open)
                        handleRoll('0')
                    setOpen(!open)
                }}
            >
                {actionsUpwards.map((action) => (
                    <SpeedDialAction
                        key={action}
                        tooltipTitle={action}
                        sx={actionStyle}
                        icon={<Icon label={action}/>}
                        onClick={(event) => {
                            event.stopPropagation()
                            handleRoll(action)
                        }}
                    />
                ))}
            </SpeedDial>

            <SpeedDial
                ariaLabel='down'
                direction='down'
                sx={{...dialStyle, top: '50%', transform: 'translateY(-11.7%)'}}
                icon={
                    open ?
                    <BsFillCheckCircleFill fontSize='30px'/> :
                    <GiCubes fontSize='30px'/>
                }
                open={open}
                onClick={() => {
                    if(open)
                        handleRoll('0')
                    setOpen(!open)
                }}
            >
                {actionsDownwards.map((action) => (
                    <SpeedDialAction
                        key={action}
                        tooltipTitle={action}
                        sx={actionStyle}
                        icon={<Icon label={action}/>}
                        onClick={(event) => {
                            event.stopPropagation()
                            handleRoll(action)
                        }}
                    />
                ))}
            </SpeedDial>

            <Roll
                open={rollOpen}
                setOpen={setRollOpen}
                rollValue={rollValue}
                rerollValue={rerollValue}
            />

        </div>
    )
}