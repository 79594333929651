import * as React from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Slider from '@mui/material/Slider'
import Button from '@mui/material/Button'

import Roll from '../overlays/Roll'
import Label from '../utils/Label'

import {RiSwordFill} from 'react-icons/ri' // Attack
import {FaPeopleArrows} from 'react-icons/fa' // Maneuver
import {BsFillShieldFill} from 'react-icons/bs' // Protect
import {GiMove} from 'react-icons/gi' // Move
import {FaWineBottle} from 'react-icons/fa' // Interact
import {PiSpiralFill} from 'react-icons/pi' // Channel

export default function Play(props) {

const boxStyle = {
    marginTop: props.bigScreen ? '70px' : '62px',
    marginBottom: '66px',
}

const sliderLabelStyle = {
    width: '100%',
    height: 5,
}

const buttonStyle = {
    width: '100%',
}

const actionStyle = {
    width: '100%',
    aspectRatio: '1/1',
    border: '1px solid black',
    borderRadius: '20%',
}

const selectedActionStyle = {
    width: '100%',
    aspectRatio: '1/1',
    border: '2px solid black',
    borderRadius: '20%',
    fontSize: '64px',
}

const actionsList = [
    'Attack',
    'Maneuver',
    'Protect',
    'Move',
    'Interact',
    'Channel',
]

const iconMap = {
    'Attack': <RiSwordFill/>,
    'Maneuver': <FaPeopleArrows/>,
    'Protect': <BsFillShieldFill/>,
    'Move': <GiMove/>,
    'Interact': <FaWineBottle/>,
    'Channel': <PiSpiralFill/>,
    '': <div/>,
}

const [rollOpen, setRollOpen] = React.useState(false)

const selectAction = (action) => {
    if (props.actions[0][1] === '')
    {
        let oldAction = props.actions[1]
        props.setActions([[0, action], oldAction])
    }
    else if (props.actions[1][1] === '')
    {
        let oldAction = props.actions[0]
        props.setActions([oldAction, [1, action]])
    }
    else
    {
        let oldAction = props.actions[0]
        oldAction[0] = 1
        props.setActions([[0, action], oldAction])
    }
}

const deselectAction = (key) => {
    let newActions = [...props.actions]
    newActions[key][1] = ''
    props.setActions(newActions)
}

return (
    <Box sx={boxStyle}>
        <Label justify='center' fontSize='24px' label='Action Planner'/>

        <Grid container spacing={1} columns={12}>

            <Grid item xs={12} sx={{paddingTop: '8px'}}/>

            <Grid item xs={2}/>
            {props.actions.map(([key, action]) => (
                <Grid item key={key} xs={4}>
                    <IconButton
                        disableRipple={true}
                        color='inherit'
                        sx={selectedActionStyle}
                        onClick={() => {deselectAction(key)}}
                    >
                        {iconMap[action]}
                    </IconButton>
                </Grid>
            ))}
            <Grid item xs={2}/>

            <Grid item xs={12} sx={{paddingTop: '8px'}}/>

            {actionsList.map((action) => (
                <Grid item key={action} xs={2}>
                    <IconButton
                        disableRipple={true}
                        color='inherit'
                        sx={actionStyle}
                        onClick={() => {selectAction(action)}}
                    >
                        <Box display='flex' flexDirection='column' alignItems='center'>
                            {iconMap[action]}
                            <Label justify='center' fontSize='7px' label={action}/>
                        </Box>
                    </IconButton>
                </Grid>
            ))}

            <Grid item xs={12} sx={{paddingTop: '8px'}}/>

        </Grid>

        <Divider sx={{margin: 1}}/>

        <Label justify='center' fontSize='24px' label='Dice Roller'/>

        <Grid container spacing={0} columns={12}>

            <Grid item xs={12} sx={{paddingTop: '12px'}}/>

            <Grid item xs={12}>
                <Box sx={sliderLabelStyle}>
                    <Label
                        justify='center'
                        fontSize={16}
                        label='Roll'
                    />
                </Box>
            </Grid>

            <Grid item xs={1}/>
            <Grid item xs={10}>
                <Slider
                    value={props.rollValue}
                    onChange={(e) => {
                        props.setRollValue(e.target.value)
                        props.setRerollValue(Math.min(props.rerollValue, e.target.value))
                    }}
                    valueLabelDisplay='auto'
                    marks
                    step={1}
                    min={0}
                    max={12}
                />
            </Grid>
            <Grid item xs={1}/>

            <Grid item xs={12} sx={{paddingTop: '12px'}}/>

            <Grid item xs={12}>
                <Box sx={sliderLabelStyle}>
                    <Label
                        justify='center'
                        fontSize={16}
                        label='Reroll'
                    />
                </Box>
            </Grid>

            <Grid item xs={1}/>
            <Grid item xs={10}>
                <Slider
                    value={props.rerollValue}
                    onChange={(e) => {props.setRerollValue(e.target.value)}}
                    valueLabelDisplay='auto'
                    marks
                    step={1}
                    min={0}
                    max={props.rollValue}
                />
            </Grid>
            <Grid item xs={1}/>

            <Grid item xs={3}/>
            <Grid item xs={6}>
                <Button
                    sx={buttonStyle}
                    variant='contained'
                    onClick={() => {setRollOpen(true)}}
                >
                    <Label
                        justify='center'
                        fontSize={24}
                        label='Roll Dice'
                    />
                </Button>
            </Grid>
            <Grid item xs={3}/>
        </Grid>
        <Roll
            open={rollOpen}
            setOpen={setRollOpen}
            rollValue={props.rollValue}
            rerollValue={props.rerollValue}
        />
    </Box>
    )
}